export default {
  organizerLanding: 'Vispārīga informācija',
  company: 'Uzņēmums',
  aboutUs: 'Par mums',
  team: 'Komanda',
  career: 'Karjera',
  contacts: 'Kontakti',
  resources: 'Organizatoriem',
  terms: 'Lietošanas noteikumi',
  policy: 'Privātuma politika',
  support: 'Zināšanu bāze',
  faq: 'FAQ',
  telegram: 'Sazinieties ar mums pa telegrammu',
  
  office: {
    title: 'Kontakti',
    street: 'st. Khreshchatyk, 10 ',
    city: 'Kijeva, Ukraina, 01001',
    address: 'Kiev, Khreshchatyk, 10'
  },
  madeBy: "made by",

}
