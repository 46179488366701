import React from 'react'
import classNames from 'classnames/bind'
import s from './BreadCrumbs.module.scss'

const cx = classNames.bind(s)

const BreadCrumbs = ({children}) => {
	return (
		<div className={cx('wrap-crumbs')}>
			<div className={'container'}>
				<div className={cx('crumbs')}>
					{children}
				</div>
			</div>
		</div>
	)
}
export default BreadCrumbs
