import {useSelector} from "react-redux";
import {getToken} from "../../../helpers/localStorage";
import ErrorPage from "../../Pages/Error";
import {Redirect} from "react-router-dom";
import React from "react";

export const Wrapper = ({Component, needToken = false}) => (props) => {
  const {globalErrorStatus} = useSelector(({Base}) => Base)
  const token = getToken()
  if (globalErrorStatus) {
    return (<ErrorPage {...props} />)
  } else if (needToken && !token) {
    return <Redirect to='/'/>
  } else if (!needToken && token) {
    return <Redirect to='/dashboard'/>
  }
  return <Component {...props} />
}
