export default {
  title: 'Meklēšanas rezultāti',
  filters:    'Filtri',
  apply:    'Lietot',
  reset:    'Atiestatīt',
  emptyResult:    'Meklēšanas rezultāts nav',
  dateFilter: {
    label:      'Atlasīt datumu / periodu:',
    range: {
      placeholder:        'Izvēlieties periodu',
      value:        'no {{start}} - līdz {{end}}'
    },
    today:      'Šodien',
    tomorrow:      'Rīt',
    thisWeek:      'Šonedēļ'
  },
  priceFilter: {
    label:      'Izvēlieties cenu kategoriju',
    free:      'Bezmaksas',
    inputPrice:      'Cenas',
    min:      'Min.',
    price:      'Maks.'
  },
  sort: {
    label:      'Kārtot',
    NEW:      'Jauns pirmais',
    PRICE:       'Dārgie pirmie',
    PRICE_DESC:     'Pirmais lēts',
    START:      'Pirmā nākšana',
    START_DESC:      'Pirmā nākotne',
  },
  objectInPage: {
    label:      'Rādīt lapā:',
    value:     '{{value}} notikumi'
  },
  pagination: {
    next:      'Tālāk',
    prev:      'Iepriekšējais',
    first:      'Pirmais',
    last:      'Pēdējais'
  }
}