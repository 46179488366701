import React, {memo} from 'react'
import classNames from 'classnames/bind'
import s from './Dots.module.scss'

const cx = classNames.bind(s)
	const Dots:React.FC<{tickets?: boolean}> = ({tickets}) => {
	return (
		<div className={cx('wrap-dots')}>
			<div className={cx('dot', {dotTicket: tickets})}>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
			</div>
		</div>
	)
}
export default memo(Dots)
