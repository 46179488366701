export default {
  up: 'Rejestracja',
  in:   'Zaloguj się',
  name:   'Imię',
  companyName: 'Nazwa firmy',
  enter: "Logowanie",
  create: 'Stwórz',
  phone:   'Numer telefonu',
  inputEmail: 'Wpisz adres e-mail użyty podczas rejestracji:',
  needHelp: 'Potrzebuję pomocy!',
  password:   'Hasło',
  emailOrPhone:   'E-mail lub numer telefonu',
  inUp:   'Logowanie / rejestracja',
  out:   'Wyjdź',
  orUp:   'Lub zarejestruj się',
  orIn:   'Lub zaloguj się',
  forgot:   'Zapomniałeś hasła?',
  forgotBtn: 'Zapomniałeś hasła',
  rememberMe: 'Żeby mnie zapamiętać',

  forgotSuccess:   'Jeśli jesteś zarejestrowany na stronie, wysłano Ci wiadomość e-mail z linkiem do zmiany hasła',
  email:   'Twój e-mail',
  slogan:   'Zarządzanie zdarzeniami i sprzedaż biletów, jak powinno być',
  recovery:   'Odzyskiwanie',
  enterNewPas:   'Wprowadź nowe hasło',
  endSign:   'Kompletna rejestracja',
  passwordReseted:   'Hasło zostało pomyślnie zmienione',
  vereficationMessage:   'Wysłano kod na numer telefonu w celu weryfikacji',
  iAgreeWith:   'Zgadzam się z',
  serviceTerms:   'warunki użytkowania',
  dataProcessing:   'przetwarzanie moich danych osobowych',
  createAccount: 'Brak konta? Stwórz To',
  loginAccount: ' Posiadasz już konto? Wejdź do niego',

  here: 'tutaj'
}