import React from 'react'

export default function IconAngle ({
  color = 'white',
  width = 8,
  height = 16,
}) {
  return (
    <svg  width={width} height={height} viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.93205 7.8571C7.91112 7.65629 7.82888 7.46678 7.6965 7.31434L1.79795 0.432706C1.72041 0.317959 1.61951 0.220891 1.50184 0.147852C1.38418 0.0748128 1.25241 0.0274591 1.11518 0.00888481C0.977939 -0.00968945 0.838324 0.000931876 0.705479 0.0400664C0.572635 0.0792009 0.449556 0.145968 0.344305 0.235976C0.239054 0.325985 0.154006 0.43721 0.0947308 0.562373C0.0354558 0.687536 0.00329167 0.823795 0.000344717 0.962253C-0.00260224 1.10071 0.0237342 1.23824 0.0776299 1.36581C0.131526 1.49339 0.211765 1.60813 0.313091 1.70253L5.66887 7.94925L0.313091 14.196C0.211765 14.2904 0.131526 14.4051 0.0776299 14.5327C0.0237342 14.6603 -0.00260224 14.7978 0.000344717 14.9362C0.00329167 15.0747 0.0354558 15.211 0.0947308 15.3361C0.154006 15.4613 0.239054 15.5725 0.344305 15.6625C0.449556 15.7525 0.572635 15.8193 0.705479 15.8585C0.838324 15.8976 0.977939 15.9082 1.11518 15.8896C1.25241 15.871 1.38418 15.8237 1.50184 15.7506C1.61951 15.6776 1.72041 15.5805 1.79795 15.4658L7.6965 8.58416C7.7823 8.48483 7.84735 8.36933 7.88781 8.24446C7.92826 8.11959 7.9433 7.98788 7.93205 7.8571Z" fill={color}/>
    </svg>
  )
}
