import {ACCESS_ROLES} from 'constants/';
import {AccessNav, OperatorNav, SalesNav, VolunteerNav} from "./constants";

export default function generateNavs(isOwner, myRole) {
  const isFullAccess = isOwner || myRole === ACCESS_ROLES.CO_ORGANIZER

  let navs = []
  if ((isFullAccess || myRole === ACCESS_ROLES.OPERATOR)) {
    navs = [...OperatorNav]
  }
  if (isFullAccess) {
    navs = [...navs, AccessNav]
  }
  if (isFullAccess || myRole === ACCESS_ROLES.SALES) {
    navs = [...navs, ...SalesNav]
  }
  if (myRole === ACCESS_ROLES.VOLUNTEER) {
    navs = [...navs, ...VolunteerNav]
  }
  return navs
}