import {gql} from '@apollo/client'

export default gql`
  mutation GetGroupDiscountSum ($ticketTypeIds: [Int]!) {
    getGroupDiscountSum (ticketTypeIds: $ticketTypeIds) {
      totalSum
      saving
    }
  }
`
