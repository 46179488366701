enum ActionTypes {
  RESET = '@@posters/RESET',
  RESET_DATE = '@@posters/RESET_DATE',
  EDIT_SEARCH = '@@posters/EDIT_SEARCH',
  EDIT_DATE_FILTER = '@@posters/EDIT_DATE_FILTER',
  EDIT_PRICE_FILTER = '@@posters/EDIT_PRICE_FILTER',
  CHANGE_SORT = '@@posters/CHANGE_SORT',
  CHANGE_OBJECTS_ON_PAGE = '@@posters/CHANGE_OBJECTS_ON_PAGE',
  OPEN_PAGE = '@@posters/OPEN_PAGE',
  SWITCH_VIEW = '@@posters/SWITCH_VIEW',
}

export default ActionTypes