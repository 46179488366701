export default {
  title: 'Результати пошуку',
  filters: 'Фільтри',
  apply: 'Застосувати',
  emptyResult: 'Пошук не дав результатів',
  reset: 'Скинути',
  dateFilter: {
    label: 'Виберіть дату / період:',
    range: {
      placeholder: 'Оберіть період',
      value: 'від {{start}} - до {{end}}'
    },
    today: 'Сьогодні',
    tomorrow: 'Завтра',
    thisWeek: 'На цьому тижні'
  },
  priceFilter: {
    label: "Виберіть цінову категорію",
    free: "Безкоштовні",
    inputPrice: "Внести ціну",
    min: "Мін.",
    price: "Макс."
  },
  sort: {
    label: "Сортування",
    NEW: 'Спочатку найновіші',
    PRICE: 'Спочатку найдорожчі',
    PRICE_DESC: 'Спочатку найдешевші',
    START: 'Спочатку найближчі',
    START_DESC: 'Спочатку найвіддаленіші',
  },
  objectInPage: {
    label: "Показувати на сторінці:",
    value: "{{value}} подій"
  },
  pagination: {
    next: "Наступна",
    prev: "Попередня",
    first: "Перша",
    last: "Остання"
  }
}
