import React, {memo, useState} from 'react'
import styles from './Dashboard.module.scss'
import classNames from 'classnames/bind'
import {Trans, withTranslation} from 'react-i18next'
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs'
import {EVENTS} from '../../../apollo/queries'
import NotFound from '../NotFound/NotFound'
import EventCart from './EventCart'
import Footer from '../../../components/Footer'
import {useQuery} from '@apollo/client'
import Loader from '../../../components/Loader'
import HeaderCustom from '../../../components/HeaderCustom/HeaderCustom'
import {Link} from 'react-router-dom'
import {ArrRight, ServerFailed500Illustration} from "../Event/Icons/Icons";
import TabsControl from "./TabsControl";

const cx = classNames.bind(styles)


const Dashboard = (props) => {
  const redirect = (path) => props.history.push(path)
  const [variables, setVariables] = useState('UPCOMING')
  const {data, loading, error, refetch} = useQuery(EVENTS, {variables: {filter: variables}, fetchPolicy: 'no-cache'})

  if (error) {
    return <NotFound dashboard><ServerFailed500Illustration/></NotFound>
  }
  return (
    <>
      <HeaderCustom
        links={
          <>
            <Link to='/dashboard'><Trans i18nKey='user.myEvents'/></Link>
            <Link to='/profile' className='profile-link'><Trans i18nKey='user.profile'/></Link>
            <Link to='/create-event' className='outline'><Trans i18nKey="event.createEvent"/></Link>

          </>}
        redirect={redirect}
      />

      <div className={cx('dashboard')}>
        <BreadCrumbs>
          <Link to={'/'}><Trans i18nKey={'event.eventCart.breadCrumb.event'}/></Link>
          <span><ArrRight/></span>
          <Link to={'#'}><Trans i18nKey={'event.eventCart.title'}/></Link>
        </BreadCrumbs>

        <div className="container">
          <h2 className={cx('mb50', 'c57', 'fz2550', 'text-center')}><Trans i18nKey={'event.eventCart.title'}/></h2>
          <TabsControl variables={variables} setVariables={setVariables}/>
          {loading ?
            <div className={cx('wrap-loader', 'text-center')}>
              <Loader/>
            </div> :

            <div className={cx('event-lists')}>
              {data && data?.events?.length > 0 ?
                data?.events.map(event => <EventCart {...event} refetch={refetch} key={event.id}/>) :
                <div className={cx('list-empty', 'c57', 'text-center')}>
                  <p><Trans i18nKey={'event.eventIsEmpty'}/></p>

                </div>
              }
            </div>}

        </div>
      </div>
      <Footer/>
    </>
  )
}


export default withTranslation()(memo(Dashboard))
