import React, { memo, ReactNode } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
const cx = classNames.bind(styles)

type InputWithButton = {
  onButtonClick?: () => void,
  onDivClick?: () => void,
  buttonType?: "button" | "submit" | "reset",
  buttonIcon?: ReactNode,
  buttonClassName?: string,
  inputClassName?: string,
  className?: string,

}

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>&InputWithButton

export default memo(function InputWithButton({
  onButtonClick, buttonType = 'button', onDivClick,buttonIcon, buttonClassName ='', inputClassName ='', className ='', ...props}: Props
) {
  return (
    <div className={cx('Component', className)} onClick={onDivClick}>
      <input {...props} className={cx('Input', inputClassName || '')}/>
      {buttonIcon && <button onClick={onButtonClick} className={cx('Button', buttonClassName)}
                             type={buttonType}>{buttonIcon}</button>}
    </div>
  )
})
