import React, {Fragment, memo, useCallback, useEffect, useState} from 'react'
import {Link, withRouter} from 'react-router-dom'
import {Trans, useTranslation} from 'react-i18next'
import IconBurger from 'components/Icons/Burger'
import s from './HeaderCustom.module.scss'
import classNames from 'classnames/bind'
import NavBar from './Navbar/Navbar'
import {getToken, logOut} from '../../helpers/localStorage'
import {useDispatch} from 'react-redux'
import {push} from "connected-react-router"
import {showSignModal} from '../../store/Base/actions'
import Preloader from '../Preloader'
import HeaderSetting from './HeaderSetting/HeaderSetting'
import Button from '../common/Button/Button'
import LanguageSwitcher from "../../containers/Pages/Landing/LanguageSwitcher";
import {renderLogo} from "../../helpers/renderLogo";

const cx = classNames.bind(s)

function HeaderCustom({
                        links, classNames, setting, history, match, changePublish = () => {
  }, redirect, ...props
                      }) {
  const [open, setOpen] = useState(false)
  const [loader, setLoader] = useState(false)
  const [fixed, setFixed] = useState(false)
  const [t] = useTranslation()
  const dispatch = useDispatch();
  const isCreateEvent = match.path === '/create-event'
  const toggleNavBar = useCallback(() => {
    setOpen(!open)
  }, [open])
  const logOutCall = useCallback(async () => {
    setLoader(true)
    await logOut()
    setLoader(false)
  }, [])
  
  const onClickCreateEventBtn = useCallback(() => {
      const token = getToken()
      if (token) {
        dispatch(push('/dashboard'))
      } else {
        dispatch(showSignModal({tab: 'login'}))
      }
    },
    [dispatch]
  )
  useEffect(() => {
    window.onscroll = () => {
      setFixed(window.scrollY > 65)
    }
  }, [])
  if (loader) {
    return <Preloader/>
  }
  return (
    <Fragment>
      <div className={cx('padding-block-dashboard', {"padding-block-setting": setting && !isCreateEvent})}>
      </div>
      <header className={cx('Component', fixed && 'Fixed', classNames ? classNames : '')}>
        <div className={cx('Container')}>
          <Link className={cx('Logo')} to='/'>
            {renderLogo()}
          </Link>
          <nav className={cx('Nav')}>
            {!!getToken() && links}
          </nav>
          <div className={cx('Actions')}>
            <LanguageSwitcher className={cx('LanguageSwitcher', 'LanguageSwitchCustom', {ml: !getToken() || !links})}
                              isShort={false}
                              onChange={props.onLanguageChange}
            />
            <button type='button' onClick={toggleNavBar} className={cx('SidebarBtn')}>
              <IconBurger/>
            </button>
          </div>
          <div className={cx('header-btns')}>
            {!!getToken()
              ? <Button callback={logOutCall}><Trans i18nKey='sign.out'/></Button>
              : <Button callback={onClickCreateEventBtn}>{t('event.createEvent')}</Button>
            }
          </div>
        
        </div>
        {setting && !isCreateEvent &&
        <HeaderSetting
          history={history}
          match={match}
          changePublish={changePublish}
        />}
      </header>
      <NavBar redirect={redirect} links={links}
              callback={onClickCreateEventBtn}
              getToken={getToken}
              onChange={props.onLanguageChange}
              isOpen={open} close={toggleNavBar}/>
    </Fragment>
  )
}


export default withRouter(memo(HeaderCustom))
