import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next';
import FieldError from 'components/UI/FieldError'
import PhoneInput from 'components/PhoneInput'
import animatedScroll from 'helpers/animatedScroll';
import formatLanguage from 'helpers/formatLanguage';
import classNames from 'classnames/bind'
import s from '../../Event.module.scss'
import SelectSearch from "./SelectSearch/SelectSearch";

const cx = classNames.bind(s)

class QuestionField extends PureComponent {
  FieldRef = React.createRef()
  
  static propTypes = {
    name: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired,
    value: PropTypes.string,
    options: PropTypes.array,
    questionType: PropTypes.string.isRequired,
    error: PropTypes.string,
    helpText: PropTypes.string,
    edit: PropTypes.func.isRequired,
    labelCustomName: PropTypes.string,
    inputWrapCustomClass: PropTypes.string,
    lng: PropTypes.string
  }
  
  static defaultProps = {
    value: ''
  }
  
  Input = () => {
    const {
      questionType: type, value, isMultilanguage, defaultLanguage,
      isRequired, edit, id, i18n, ...questionTypeData
    } = this.props
    
    const formattedDefaultLng = formatLanguage(defaultLanguage)
    const formattedCurrentLng = formatLanguage(this.props.lng)
    
    const translatedOptions = !isMultilanguage
      ? questionTypeData.options
      : (
        (questionTypeData)[`options${formattedCurrentLng}`]
        || (questionTypeData)[`options${formattedDefaultLng}`]
      )
    
    
    switch (type) {
      case 'TEXTAREA':
        return <textarea id={id}
                         onBlur={e => edit(e.target.value)}
                         defaultValue={value}
                         className={cx(this.props.inputWrapCustomClass)}
                         required={isRequired}/>
      case 'INPUT':
      case 'EMAIL':
      case 'URL':
        return <input
          id={id}
          type={type === 'INPUT' ? 'text' : type.toLowerCase()}
          onChange={(e) => {
            let value = e && e.target.value;
            type === 'INPUT' ? (value.length < 30 && edit(value)) : edit(value)
          }}
          value={value}
          className={cx(this.props.inputWrapCustomClass)}
          required={isRequired}
        />
      case 'DROPDOWN':
      case 'SELECT':
        return <>
          <SelectSearch
            data={translatedOptions}
            selected={value}
            width={'100%'}
            classNamesWrap={cx('simpleInputCustom')}
            required={isRequired}
            // border0
            withSearch={type === 'DROPDOWN'}
            onChange={(index, el) => {
              if (type === 'DROPDOWN') {
                translatedOptions.forEach((element, index) => el === element && edit(translatedOptions[index]))
              } else {
                edit(translatedOptions[index])
              }
            }
            }
          />
        </>
      case 'PHONE_NUMBER':
        return <PhoneInput
          classNames={this.props.inputWrapCustomClass}
          value={value}
          onBlur={(e) => edit(e.target.value)}/>
      default:
        return null
    }
  }
  
  
  componentDidUpdate = (prevProps) => {
    if (!prevProps.error && this.props.error) {
      animatedScroll(null, this.FieldRef.current)
    }
  }
  
  render() {
    const {
      labelCustomName,
      inputWrapCustomClass,
      isRequired,
      error,
      i18n,
      isDefault,
      isMultilanguage,
      defaultLanguage,
      lng,
      ...questionTypeData
    } = this.props
    
    const formattedDefaultLng = formatLanguage(defaultLanguage)
    const formattedCurrentLng = formatLanguage(this.props.i18n.language || lng)
    let translatedName = isDefault
      ? (questionTypeData)[`name${formattedCurrentLng}`]
      : !isMultilanguage
        ? questionTypeData?.name
        : (
          (questionTypeData)[`name${formattedCurrentLng}`]
          || (questionTypeData)[`name${formattedDefaultLng}`]
        )
    let translatedHelpText = !isMultilanguage
      ? questionTypeData.helpText
      : (
        (questionTypeData)[`helpText${formattedCurrentLng}`]
        || (questionTypeData)[`helpText${formattedDefaultLng}`]
      )
    if (!translatedName) {
      translatedName = questionTypeData.name
    }
    
    translatedHelpText = translatedHelpText && translatedHelpText?.replace(/(http|https)\:\/\/(\S+)/g, '<a href="$1://$2" target="_blank" rel="nofollow">$1://$2</a>');
    return (
      <div ref={this.FieldRef} className={cx('question')}>
        <label className={cx(labelCustomName)}>
          {/** use or author's translations or local translations */}
          {translatedName} {isRequired && <span className={'require'}>*</span>}
        </label>
        {error && <FieldError error={error} classNames={cx('text-error')}/>}
        <this.Input/>
        <div className={`text-small help-text ${cx('text-help')}`}>
          {<div dangerouslySetInnerHTML={{__html: translatedHelpText}}>
          </div>}
        </div>
      </div>
    )
  }
}

export default withTranslation()(QuestionField)
