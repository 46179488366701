import React, {PureComponent} from 'react'
import './index.scss'
import PropTypes from 'prop-types'
import uuid from "react-uuid";
import {Trans} from "react-i18next";

export default class Checkbox extends PureComponent {
  static propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    required: PropTypes.bool,
    labelClassName: PropTypes.string,
    size: PropTypes.string,
    circle: PropTypes.bool,
    custom: PropTypes.bool,
    trans: PropTypes.bool
  }

  static defaultProps = {
    checked: false,
    onChange: () => null,
    size: 'small',
    trans: false
  }

  componentDidMount() {
    if (!this.props.id && this.props.label) {
      this.setState({id: uuid().toString()})
    }
  }

  state = {
    id: this.props.id
  }

  render() {
    const {checked, onChange, required, className, custom = false, label, labelClassName, size, circle, trans} = this.props
    const {id = ''} = this.state

    return (
      <div className={`checkbox-component  ${className} ${custom ? 'custom' : ''} ${size ? size : ''} `}>
        <input
          onChange={(e) => onChange(e.target.checked)}
          type="checkbox"
          id={id}
          required={required}
          checked={checked}/>
        <span className={`checkmark  ${size ? size : ''} ${circle ?'circle': ''}`}>
         {!circle && <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path
             d="M7.78397 1.39936L3.16105 5.98518C3.03503 6.11019 2.83151 6.10939 2.7065 5.98336L0.274868 3.53204C0.149859 3.40601 0.150662 3.2025 0.276716 3.07746L0.88526 2.47382C1.01129 2.34881 1.21481 2.34961 1.33981 2.47566L2.94139 4.09023L6.7276 0.334441C6.85363 0.209432 7.05714 0.210236 7.18215 0.336263L7.7858 0.94478C7.91081 1.07083 7.91 1.27435 7.78397 1.39936Z"
             fill="#8C8C8C"/>
         </svg>}
          {circle && <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4.50035" cy="4.49987" r="3.86364" fill="#8C8C8C"/>
          </svg>
          }
          </span>
        {label && <label className={labelClassName} htmlFor={id}>
          {trans?<Trans i18nKey={label}/> : label}
        </label>}
      </div>
    )
  }
}
