import React from 'react'
import SATCHEL_Logo from 'components/Icons/Logo/SATCHEL';
import TEEKO_Logo from 'components/Icons/Logo/TEEKO';

const app_type = process.env.REACT_APP_TYPE || 'TEEKO'
export const renderLogo = () => {
  if (app_type === 'SATCHEL') {
    return <SATCHEL_Logo/>
  }
  return <TEEKO_Logo/>
}
