import {createAction} from 'helpers/createAction'
import ActionTypes from './actionTypes'

export const setErrorStatus = (payload = null) => createAction(ActionTypes.SET_ERROR_STATUS, payload)

export const edit = ({field, value}) => createAction(ActionTypes.EDIT, {field, value})

/**
 * @typedef { 'sign up' | 'login' } TabType - What is tab should be display first?
 *
 * @typedef { object | TabType | null } SignModalConfig - if this is null - hide modal. If `TabType` or `object` display modal with current config
 * @property { TabType } tab - same logic, as if we will set `SignModalConfig` as `TabType`
 * @property { bool } [disableClose] - Does user have possible to close modal?
 * @property { bool } [hideTabs] - hide tabs to switch between sign up and login. User will remain on the open tab
 *
 * @param {{tab: string}} payload
 */
export const showSignModal = (payload) => createAction(ActionTypes.SHOW_SIGN_MODAL, payload)
