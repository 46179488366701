import React, { PureComponent } from 'react'

export default class IconFacebook extends PureComponent {
  render() {
    return (
      <svg width="21" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M15.6.2v3.2h-1.9c-.6 0-1.1.1-1.3.4-.3.3-.4.7-.4 1.3v2.3h3.5l-.5 3.5h-3V20H8.3v-9h-3V7.3h3V4.8c0-1.5.5-2.7 1.3-3.5.8-.8 2-1.2 3.3-1.2l2.7.1z" fill="#575757"/>
      </svg>
    )
  }
}
