import error from './error'
import landing from './landing'
import sign from './sign'
import event from './event'
import user from './user'
import footer from './footer'
import languages from './languages'
import searchPage from './search_page'
import postersPage from './posters_page'

export default {
  sign,
  error,
  event,
  landing,
  user,
  languages,
  searchPage,
  postersPage,
  footer,
  showBy: 'Pokaż przez',
  show: 'Pokazać',
  copy: 'Kopiuj',
  copied: 'Skopiowano',
  aliasDescr1:  'Możesz określić unikalny alias i podać linki do osób, na Facebooku itp. tak jak:',
  aliasDescr2: 'Jest to wygodne, ponieważ ludzie będą widzieć tylko Twoje wydarzenia.',
  learnMore: 'Dowiedz się więcej',
  subscribeForm: {
    header: 'Otrzymuj wiadomości e-mail od teeko, aby być na bieżąco z aktualizacjami!',
    success:      'Dziękujemy za subskrybowanie 😁'
  },
  ticketActivation: {
    title: 'Aktywacja biletu',
    ticketCreated:      'Bilet wydany',
    order:      'Nakaz'
  },
  toHome: 'Dom',
  cancel:    'Anuluj',
  notifications: {
    smsSent: 'SMS wysłano pomyślnie',
    saved:      'Zapisane',
    error:      'Wystąpił błąd!',
  },
  verify: {
    sendEmailAgain: 'Ponownie wyślij e-mail',
    verificatePhone:      'Potwierdź numer telefonu',
    emailVerificated:      'Gratulacje, e-mail potwierdzony!',
    emailSended:      'Wysłano e-mail',
    email:      'Potwierdź swój adres e-mail',
    phone:      'Potwierdź swój numer telefonu'
  },
  changeLang: 'Zmień język',
  terms: {
    title: 'Zasady obsługi',
    tabs: {
      organizer: 'Dla organizatorów',
      customer:        'Dla kupujących'
    }
  },
  policy: {
    title: 'Polityka prywatności'
  },
  cookie: {
    text: 'Korzystając z teeko, wyrażasz zgodę na naszą politykę dotyczącą plików cookie. <0> Dowiedz się więcej. </ 0> ',
    accept:      'Akceptuj',
  },
  send: 'Wyślij',
  remove:    'Usuń',
  download:    'Pobierz',
  weekDays: ['Pn', 'Wt', 'Śr', 'Cz', 'Piątek', 'Sob', 'Słońce']
}
