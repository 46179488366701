export default {
  'Enter': 'Введите',
  profile: 'Мой кабинет',
  account: 'Мой аккаунт',
  general: 'Общие',
  finances: 'Финансы',
  infoFull: 'Ваш аккаунт заполнен полностью',
  infoNotFull: 'Ваш аккаунт заполнен не полностью',
  editData: 'Редактировать данные',
  cashWithdrawal: 'Вывод денег',
  withdrawMoney: 'Вывести деньги',
  cashDescription: 'Вы можете выводить деньги на уже существующие счета, либо создать новый, нажав кнопку “добавить новый счет”',
  addNewWayPay: 'Добавить новый способ оплаты',
  personInfo: 'Персональная информация',
  editDescription: 'Поля помеченые звездочкой, обязательны для заполнения.',
  uploadImageDescription: 'Загрузите скан-копии документов (паспорт, идентификационный номер, выписки из единого реестра, справка из банка об открытии счета)',
  uploadImageDescriptionNatural: 'Загрузите скан-копии документов (паспорт, идентификационный номер)',
  uploadImageDetail1: 'сканы паспорта с 1 по 11 страницу, включая страницы регистрации и следующую пустую (чтобы финансовому процессингу было видно, что прописка в сканах стоит самая последняя)',
  uploadImageDetail2: 'сканы должны быть в развороте (по 2 страницы на скан)',
  uploadImageDetail3: 'скан внутренней ID карточки, которая вместо паспорта должен быть с двух сторон',
  uploadImageFormat: 'Файлы должны быть в форматах: jpeg, jpg,pdf',
  dragInDrop: 'Перетащите сюда файл или',
  dragInDropImage: 'Перетащите изображение сюда или',
  uploadFileWithPC: 'загрузите с компьютера',
  reset: 'Сбросить',
  refresh: 'Обновить',
  scanType: {
    PASSPORT: 'Паспорт',
    PASSPORT_PE: 'Копия паспорта ФЛП (всех разворотов, где есть записи и разворота прописки даже если его нет)',
    PASSPORT_OOO: 'Паспорта директора юр.лица (стр. 1-6, прописка и следующая страница за ней)',
    PASSPORT_OO: 'Паспорта директора юр.лица (стр. 1-6, прописка и следующая страница за ней)',

    INN: 'Идентификационный номер',
    INN_PE: 'Копия ИНН ФЛП',
    INN_OOO: 'ИНН директора юр. лица',
    INN_OO: 'ИНН директора юр. лица',

    REGISTRY_REPORT_PE: 'Выписка из Единого гос. Реестр / Свидетельство о государственной регистрации ФЛП ',
    REGISTRY_REPORT_OOO: 'Выписки из ЕГР/свидетельство про гос. регистрацию юр.лица',
    REGISTRY_REPORT_OO: 'Выписки из ЕГР/свидетельство про гос. регистрацию юр.лица',

    BANK_REPORT_PE: 'Справка из банка об открытии текущего счета',
    BANK_REPORT_OOO: 'Справка об открытии расчетного счета из Банка (нового образца с указанием IBAN)',
    BANK_REPORT_OO: 'Справка об открытии расчетного счета из Банка (нового образца с указанием IBAN)',

    COMPANY_STATUTE_OOO: 'Устава юр. лица',
    COMPANY_STATUTE_OO: 'Устава юр. лица',
    ORG_STRUCTURE_OOO: 'Структура собственности',
    ORG_STRUCTURE_OO: 'Структура собственности',

    DIRECTOR_PROTOCOL_OOO: 'Протокола, а также приказа о назначении директора',
    DIRECTOR_PROTOCOL_OO: 'Протокола, а также приказа о назначении директора',
    FINANCE_REPORT_OO: 'Финансовый отчет за год',
    FONDY_CONTRACT: '',
  },

  verificationType: {
    PENDING: 'проходит верификацию',
    CANCELED: 'не подтвержден',
    VERIFIED: 'подтвержден'
  },
  finance: {
    PENDING: 'Счет проходит проверку, пожалуйста ожидайте',
    CANCELED: 'Счет не принят, пожалуйста обратитесь в службу поддержки',
    VERIFIED: 'Счет принят',
    rs: 'р/с',

    paymentOnАccount: 'Оплата на р/с',
    paymentOnCard: 'Оплата на карту',
    companyName: 'Название компании, принимающей платежи',
    mfo: 'МФО',
    edrpou: 'ЕДРПОУ',
    creditCard: 'Кредитная карта',
    bankAccountNumber: 'Номер расчетного счета',
    uploadPassport: 'Загрузить скан паспорта (jpg, png)',
    uploadId: 'Загрузить скан ид. номера (jpg, png)',
    cardNumber: 'Введите номер карты',
    uploadContract: 'Загрузить скан подписанного договора с fondy',
    naturalPersons: 'Физлицо',
    naturalPersonsFull: 'Физическое лицо',
    privateEntrepreneurFull: 'Физическое лицо предприниматель',
    oooFull: 'Общество с ограниченной ответственностью',
    ooFull: 'Общественная организация',
    privateEntrepreneur: 'ФЛП',
    ooo: 'OOO',
    oo: 'OO',
    ltd: 'ООО',
    iban: 'IBAN',

    statement: 'Скан выписки из единого реестра',
    sertificateFromBank: 'Cправка из банка об открытии счета',
  },
  addNewFinance: 'Добавить новый счет',
  email: 'Email',
  firstName: 'Имя',
  lastName: 'Фамилия',
  phone: 'Номер телефона',
  birthday: 'Дата рождения',
  changePassword: 'Изменить пароль',
  oldPassword: 'Старый пароль',
  newPassword: 'Новый пароль',
  passwordChanged: 'Пароль успешно изменен.',
  myEvents: 'Мои события'
}
