export default function $() {
}

$.create = (tagName, src, options = {}) => {
  if (!tagName) return false
  const $el = document.createElement(tagName)
  if (Object.keys(options).length > 0) {
    Object.keys(options)
      .forEach(key => {
        $el[key] = options[key]
      })
  }
  if (src) {
    $el.src = src
  }
  return $el
}
