export default {
  long: {
    ru: 'Rosyjski',
    en: 'Angielski',
    uk: 'Ukraiński',
    he: 'Hebrajski',
    pl: 'Polski',
    lv: 'Łotewski'
  },
  short: {
    ru: 'Rus',
    en: 'Ang',
    uk: 'Ukr',
    he: 'Ivr',
    pl: 'Paul',
    lv: 'Lat'
  },
  /**
   * remove
   */
  ru: 'Rosyjski',
  en: 'Angielski',
  uk: 'Ukraiński',
  he: 'Hebrajski',
  pl: 'Polski',
  lv: 'Łotewski'
}