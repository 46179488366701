import {animated, useSpring} from 'react-spring'
import React, {useEffect} from 'react'
import s from './ImageParallax.scss'
import classNames from "classnames";
import {
  BigBook,
  BigBox, BookIcon,
  BoxShadow,
  ShadowSignIn,
  SmallBig,
  TicketLoginIcon,
  UserIcon,
  UserIconSignIn
} from "./ParallaxIcon";

const cx = classNames.bind(s)
const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]

const ticket = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`
const ticketBook = (x, y) => `translate3d(${x / 8 + 120}px ,${y / 10}px,0)`
const box1 = (x, y) => `translate3d(${x / 9.5}px,${y / 9}px,0)`
const box2 = (x, y) => `translate3d(${x / 8 + 15}px,${y / 10}px,0)`
const shadow = (x, y) => `translate3d(${x / 10}px,${y / 7}px,0)`

const trans1 = (x, y) => `translate3d(${x / 20}px,${y / 20}px,0)`
const trans2 = (x, y) => `translate3d(${x / 18}px,${y / 18}px,0)`
const trans3 = (x, y) => `translate3d(${x / 16}px,${y / 16}px,0) scale(1.3)`
const trans4 = (x, y) => `translate3d(${x / 13.5}px,${y / 13.5}px,0) scale(1.5)`



const ImageParallax = ({isLogin, x, y}) => {
  const [props, set] = useSpring(() => ({xy: [0, 0], config: {mass: 10, tension: 550, friction: 140}}))
  useEffect(() => {
    set({xy: calc(x, y)})
  }, [x, y])
  return <div className={cx('imageForm')}>
    {isLogin && <>
      <animated.div style={{transform: props.xy.interpolate(trans1)}} className={cx('icon-1')}><UserIcon/></animated.div>
      <animated.div style={{transform: props.xy.interpolate(trans2)}} className={cx('icon-2')}><UserIcon/></animated.div>
      <animated.div style={{transform: props.xy.interpolate(trans3)}} className={cx('icon-3')}><UserIcon/></animated.div>
      <animated.div style={{transform: props.xy.interpolate(trans4)}} className={cx('icon-4')}><UserIcon/></animated.div>
      <animated.div style={{transform: props.xy.interpolate(ticket)}} className={cx('ticket')}><TicketLoginIcon/></animated.div>
      <animated.div style={{transform: props.xy.interpolate(box1)}} className={cx('big-box')}><BigBox/></animated.div>
      <animated.div style={{transform: props.xy.interpolate(box2)}} className={cx('small-box')}><SmallBig/></animated.div>
      <animated.div style={{transform: props.xy.interpolate(shadow)}} className={cx('box-shadow')}><BoxShadow/></animated.div>
    </>}
    {!isLogin && <>
      <animated.div style={{transform: props.xy.interpolate(trans1)}} className={cx('icon-1')}><BookIcon rotate={"20"}/></animated.div>
      <animated.div style={{transform: props.xy.interpolate(trans1)}} className={cx('icon-5')}><BookIcon  rotate={"-30"} scale={"0.7"}/></animated.div>
      <animated.div style={{transform: props.xy.interpolate(trans1)}} className={cx('icon-4', 'icon-6')}><BookIcon  scale={"1.3"}/></animated.div>
      <animated.div style={{transform: props.xy.interpolate(ticketBook)}} className={cx('ticket')}><BigBook/></animated.div>
      <animated.div style={{transform: props.xy.interpolate(ticket)}} className={cx('ticket')}><UserIconSignIn/></animated.div>
      <animated.div style={{transform: props.xy.interpolate(shadow)}} className={cx('shadow')}><ShadowSignIn /></animated.div>
    </>}

  </div>
}
export default ImageParallax