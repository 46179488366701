import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import s from './index.module.scss'
import { CSSTransition } from 'react-transition-group';
import {CloseIcon} from "../../containers/Pages/Event/Icons/Icons";

export default class ModalWrapper extends PureComponent {
  state = {
    isOpen: false
  }

  static propTypes = {
    isOpen: PropTypes.bool,
    close: PropTypes.func.isRequired,
    modalClassName: PropTypes.string,
    overlayClassName: PropTypes.string,
    timeout: PropTypes.number,
  }

  static defaultProps = {
    timeout: 300
  }
  componentDidMount() {
    this.setState({isOpen: this.props.isOpen})
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.isOpen && !this.props.isOpen) {
      document.body.style.overflow = 'auto'
      document.body.style.paddingRight = '0px'
      document.onkeydown = null
      this.closeModal()
    } else if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({ isOpen: true })
      document.body.style.overflow = 'hidden'
      document.body.style.paddingRight = '20px'
      document.onkeydown = (e) => {
        e.key === 'Escape' && this.props.close()
      }
    }
  }

  closeModal = () => {
    this.setState({ isOpen: false })
    setTimeout(() => {
      if(this.props.close){
        this.props.close()
      }
    }, this.props.timeout)
  }

  render() {
    return (
      <CSSTransition
        in={this.state.isOpen}
        timeout={this.props.timeout}
        classNames={{
          // appear: s.Overlay,
          enter: s['Overlay-enter'],
          enterActive: s['Overlay-enter-active'],
          exit: s['Overlay-exit'],
          exitActive: s['Overlay-exit-active'],
        }}
        unmountOnExit>
        <div
          onClick={this.closeModal}
          className={`${s.Overlay} ${this.props.className || ''}`}>
          <div className={`${s.Modal} ${this.props.modalClassName || ''}`} onClick={e => e.stopPropagation()}>
            <button
              className={'CloseBtn'}
              onClick={e => {
                e.preventDefault()
                this.closeModal()
              }}>
              <CloseIcon fill={'var(--accent-color)'} />
            </button>

            {this.props.children}
          </div>
        </div>
      </CSSTransition>
    )
  }
}
