import React, {lazy, memo, Suspense, useEffect} from 'react'
import {ConnectedRouter} from 'connected-react-router'
import {Route, Router, Switch} from 'react-router-dom'
import {connect} from 'react-redux'
import ModalPhoneVerification from 'containers/ModalPhoneVerification'
import Notifications from 'components/Notifications'
import {requestProfile} from 'store/Profile/actions'
import 'scss/index.scss'
import ModalSign from 'containers/ModalSign/ModalSign'
import Preloader from 'components/Preloader'
import {STATIC_PATHNAMES} from 'constants'
import NotFound from '../Pages/NotFound/NotFound'
import Dashboard from '../Pages/Dashboard/Dashboard'
import AgreementCookie from "../Pages/Landing/AgreementCookie";
import Event from 'containers/Pages/Event'
import Posters from 'containers/Pages/Posters'
import OwnPosters from 'containers/Pages/Posters/OwnPosters'
import {NotFoundIllustation} from "../Pages/Event/Icons/Icons";
import CheckIE from "../../components/CheckIE/CheckIE";
import $ from 'helpers/dom'
import {Wrapper} from "./components/Wrapper";


const Profile = lazy(() => import('containers/Pages/Profile/Profile'))
const Search = lazy(() => import('containers/Pages/Search'))
const Landing = lazy(() => import('containers/Pages/Landing'))
const TicketActivation = lazy(() => import('containers/Pages/TicketActivation'))
const SignUp = lazy(() => import('containers/Pages/SignUp'))
const EventEdit = lazy(() => import('containers/Pages/EventEdit/EventEdit'))
const ResetPassword = lazy(() => import('containers/Pages/ResetPassword'))
const EmailVerification = lazy(() => import('containers/Pages/EmailVerification'))
const Terms = lazy(() => import('containers/Pages/Terms'))
const Static = lazy(() => import('containers/Pages/Static'))


function App(props) {
  useEffect(() => {
    props.requestProfile()
    const script = $.create('script', 'https://api.fondy.eu/static_common/v1/checkout/ipsp.js')
    document.body.appendChild(script);
    props.history.listen(() => {
      window.scrollTo(0, 0)
      process.env.REACT_APP_TYPE === 'TEEKO' && window && window.Intercom && window.Intercom("update");
    })
  }, [])
  
  const {pathname} = props.history.location
  return (
    <ConnectedRouter history={props.history}>
      <Router history={props.history}>
        <div className="app">
          <ModalPhoneVerification/>
          <ModalSign/>
          <AgreementCookie/>
          <CheckIE/>
          <Notifications/>
          <Suspense fallback={<Preloader/>}>
            <Switch>
              <Route path='/' exact
                     component={Posters}/>
              <Route path='/dashboard'
                     component={Wrapper({Component: Dashboard, needToken: true, pathname: pathname})}/>
              <Route path='/all-events-from/:alias' component={OwnPosters}/>
              <Route path='/search' component={Search}/>
              <Route path='/organizers' component={Landing}/>
              <Route path='/ticket-activation/:code' component={TicketActivation}/>
              <Route path='/signup/:token' exact component={Wrapper({Component: SignUp, needToken: false})}/>
              <Route path='/profile' component={Wrapper({Component: Profile, needToken: true})}/>
              <Route path='/event-edit/:slug' component={Wrapper({Component: EventEdit, needToken: true})}/>
              <Route path='/event/:slug' exact component={Event}/>
              <Route path='/event' component={Event}/>
              <Route path='/create-event' component={Wrapper({Component: EventEdit, needToken: true})}/>
              <Route path='/reset-password/:id/:code'
                     component={Wrapper({Component: ResetPassword, needToken: false})}/>
              <Route path='/verify-email/:id/:code' component={EmailVerification}/>
              {STATIC_PATHNAMES.map(path => <Route key={path} path={path}
                                                   component={path === '/terms' ? Terms : Static}/>)}
              <Route path="*"><NotFound><NotFoundIllustation/></NotFound></Route>
            </Switch>
          </Suspense>
        </div>
      </Router>
    </ConnectedRouter>
  )
}

export default connect(null, {requestProfile})(memo(App))
