import error from './error'
import landing from './landing'
import sign from './sign'
import event from './event'
import user from './user'
import footer from './footer'
import languages from './languages'
import searchPage from './search_page'
import postersPage from './posters_page'

export default {
  sign,
  error,
  event,
  landing,
  user,
  languages,
  searchPage,
  postersPage,
  footer,
  showBy: 'Show by',
  show: 'Show',
  learnMore: 'Learn more',
  copy: 'Copy',
  copied: 'Copied',
  aliasDescr1:  'You can specify a unique alias and give links to people, on facebook, etc. as:',
  aliasDescr2: 'This is convenient since people will only see your events.',
  subscribeForm: {
    header: 'Receive emails from teeko to keep up to date with updates!',
    success: 'Thank you for subscribing 😁'
  },
  ticketActivation: {
    title: 'Ticket Activation',
    ticketCreated: 'Ticket issued',
    order: 'order'
  },
  toHome: 'Home',
  cancel: 'Cancel',
  notifications: {
    smsSent: 'SMS sent successfully.',
    saved: 'Saved',
    error: 'An error has occurred!',
  },
  verify: {
    sendEmailAgain: 'Send email again',
    verificatePhone: 'Verify phone number',
    emailVerificated: 'Congratulations, email confirmed!',
    emailSended: 'Email sent',
    email: 'Confirm your email.',
    phone: 'Confirm your phone number'
  },
  changeLang: 'Change language',
  terms: {
    title: 'Terms of Service',
    tabs: {
      organizer: 'For organizers',
      customer: 'For buyers'
    }
  },
  policy: {
    title: 'Privacy Policy'
  },
  cookie: {
    text: 'Using teeko, you agree to our cookie policy. <0> Learn more. </ 0> ',
    accept: 'Accept',
  },
  send: 'send',
  remove: 'Remove',
  download: 'Download',
  weekDays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
}
