import React from 'react'

function TicketBG({ size = 190 }: { size?: 190|270 }) {
  if (size === 270) {
    return(
      <svg width="270" height="60" viewBox="0 0 270 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 7C0 3.13401 3.13401 0 7 0H263C266.866 0 270 3.13401 270 7V16C262.268 16 256 22.268 256 30C256 37.732 262.268 44 270 44V53C270 56.866 266.866 60 263 60H7C3.13401 60 0 56.866 0 53V44C7.73199 44 14 37.732 14 30C14 22.268 7.73199 16 0 16V7Z" fill="var(--accent-color)"/>
      </svg>
    )
  }

  return (
    <svg width="190" height="60" viewBox="0 0 190 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 7C0 3.13401 3.13401 0 7 0H183C186.866 0 190 3.13401 190 7V16C182.268 16 176 22.268 176 30C176 37.732 182.268 44 190 44V53C190 56.866 186.866 60 183 60H7C3.13401 60 0 56.866 0 53V44C7.73199 44 14 37.732 14 30C14 22.268 7.73199 16 0 16V7Z" fill="var(--accent-color)"/>
    </svg>
  )
}

export default TicketBG
