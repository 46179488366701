import React, {useState} from 'react'
import {Trans, withTranslation} from 'react-i18next';
import s from './index.module.scss'
import classNames from 'classnames/bind'
import Button from '../Button';
import {Link} from 'react-router-dom';

const cx = classNames.bind(s)

function AgreementCookie() {
  const [disable, setDisable] = useState(Boolean(localStorage.getItem('cookie')))
  const [hide, setHide] = useState(false)
  const setCookiesAgreement = () => {
    localStorage.setItem('cookie', 'true')
    setTimeout(() => setDisable(true), 1000 /**Should be same, as transition duration */)
    setHide(true)
  }

  if (disable) return null

  return (
    <div className={cx('Component', hide && 'Component_hidden')}>
      <div className={cx('Container')}>
        <p><Trans i18nKey='cookie.text'><Link className={cx('Link')} to="/terms">.</Link></Trans></p>
        <Button text='cookie.accept' className={cx('Button')} onClick={setCookiesAgreement}/>
      </div>
    </div>
  )
}

export default withTranslation()(AgreementCookie)