import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import s from '../../../Event.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(s)

export default class InputNumber extends PureComponent {
  static propTypes = {
    classNames: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    requried: PropTypes.bool,
    pattern: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }

  static defaultProps = {
    min: 0,
    value: 0,
    pattern: '[0-9]+',
    required: false,
    step: 1,
  }

  getValue = (e) => {
    let value = (e.target.validity.valid || e.target.value === '') ? (e.target.value === '' ? 0 : e.target.value) : (this.props.value || 0)
    if (typeof this.props.min === 'number' && this.props.min > value) value = this.props.min
    if (typeof this.props.max === 'number' && this.props.max < value) value = this.props.max
    return value
  }

  onChange = (e) => {
    this.props.onChange && this.props.onChange(this.getValue(e))
  }

  onBlur = (e) => {
    this.props.onBlur && this.props.onBlur(this.getValue(e))
  }

  onBtnClick = (e, diff) => {
    e.preventDefault()
    const value = +this.props.value + diff
    this.props.onChange && this.props.onChange(value)
    this.props.onBlur && this.props.onBlur(value)
  }

  render() {
    const {
      classNames, pattern, required,
      step, value, min, max,
    } = this.props

    return (
      <>
        <div className={cx('count', classNames ? classNames : '')}>
          <button className={cx('minus')}
                  onClick={e => this.onBtnClick(e, -step)}
                  disabled={+value - step < +min}>
            <span></span>
          </button>
          <input pattern={pattern}
                 required={required}
                 value={value}
                 className={s.Input}
                 onChange={this.onChange}
                 onBlur={this.onBlur}/>
          <button className={cx('plus')}
                  onClick={e => this.onBtnClick(e, step)}
                  disabled={+value + step > +max}>
            <span></span>
          </button>
        </div>
      </>
    )
  }
}
