export const getSubdomain = () => {
  let subdomain = ''
  const variableEnv = window.location.host
  switch (variableEnv) {
    case 'demo.teeko.io':
      subdomain = 'demo.'
      break
    case 'localhost:3000':
      subdomain = 'demo.'
      break
    default:
      subdomain = ''
  }
  return subdomain
}