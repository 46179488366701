import ActionTypes  from './actionTypes'

export const SW_INIT = 'SW_INIT';
export const SW_UPDATE = 'SW_UPDATE';


export const initialState = {
  defaultLanguage: 'ru',
  globalErrorStatus: null,
  showSignModal: null,
  showPhoneVerification: false,
  networkError: null,

  serviceWorkerInitialized: false,
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: null,
  emailConfirm: false,
  phoneConfirm: false

}

const Base = (state = initialState, action) => {
  switch (action.type) {
    case SW_INIT:
      return {
        ...state,
        serviceWorkerInitialized: !state.serviceWorkerInitialized,
      };
    case SW_UPDATE:
      return {
        ...state,
        serviceWorkerUpdated: !state.serviceWorkerUpdated,
        serviceWorkerRegistration: action.payload,
      };
    case ActionTypes.SET_ERROR_STATUS:
      return { ...state, globalErrorStatus: action.payload }
    case ActionTypes.SHOW_SIGN_MODAL:
      return { ...state, showSignModal: action.payload }
    case ActionTypes.EDIT:
      return { ...state, [action.payload.field]: action.payload.value }
    default:
      return state
  }
}

export default Base