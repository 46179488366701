import React, {Component, PureComponent} from 'react'
import PropTypes from 'prop-types'

export default class Loader extends PureComponent {
  static propTypes = {
    color: PropTypes.string,
    style: PropTypes.object,
  }

  static defaultProps = {
    color: 'var(--accent-color)'
  }

  render() {
    return (
      <span className={this.props.className || ''} style={this.props.style}>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          width="24px" height="30px" viewBox="0 0 24 30" style={{enableBackground: 'new 0 0 50 50'}} space="preserve">
          <rect x="0" y="13" width="4" height="5" fill={this.props.color}>
            <animate attributeName="height" attributeType="XML"
              values="5;21;5"
              begin="0s" dur="0.6s" repeatCount="indefinite" />
            <animate attributeName="y" attributeType="XML"
              values="13; 5; 13"
              begin="0s" dur="0.6s" repeatCount="indefinite" />
          </rect>
          <rect x="10" y="13" width="4" height="5" fill={this.props.color}>
            <animate attributeName="height" attributeType="XML"
              values="5;21;5"
              begin="0.15s" dur="0.6s" repeatCount="indefinite" />
            <animate attributeName="y" attributeType="XML"
              values="13; 5; 13"
              begin="0.15s" dur="0.6s" repeatCount="indefinite" />
          </rect>
          <rect x="20" y="13" width="4" height="5" fill={this.props.color}>
            <animate attributeName="height" attributeType="XML"
              values="5;21;5"
              begin="0.3s" dur="0.6s" repeatCount="indefinite" />
            <animate attributeName="y" attributeType="XML"
              values="13; 5; 13"
              begin="0.3s" dur="0.6s" repeatCount="indefinite" />
          </rect>
        </svg>
      </span>
    )
  }
}
