import {applyMiddleware, createStore} from "redux";
import rootReducer from "./reducers";
import thunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'

import {createBrowserHistory} from 'history'
import {routerMiddleware} from 'connected-react-router'

export const history = createBrowserHistory()

const store = createStore(
  rootReducer(history),
  composeWithDevTools(applyMiddleware(thunk, routerMiddleware(history)))
)

export default store
