import React, {PureComponent} from 'react'
import classNames from 'classnames/bind';
import s from 'components/SelectCustom/SelectCustom.scss';
import {IconAngle} from "containers/Pages/Event/Icons/Icons";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import PropTypes from 'prop-types'

const cx = classNames.bind(s);

class SelectSearch extends PureComponent {
  static propTypes = {
    border0: PropTypes.bool,
    selected: PropTypes.string,
    inputActive: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object),
    staticData: PropTypes.arrayOf(PropTypes.object),
    label: PropTypes.string,

  }
  state = {
    selected: this.props.selected,
    inputActive: false,
    data: this.props.arrayOfObject ? this.props.data : this.props.data?.map((el, index) => ({value: el, index})),
    staticData: this.props?.arrayOfObject ? this.props?.data : this.props?.data?.map((el, index) => ({
      value: el,
      index
    })),
    label: this.props.label,
    searchValue: this.props.selected,
    isSearch: false,
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.selected !== prevProps.selected) {
      this.setState({selected: this.props.selected, searchValue: this.props.selected})
    }
  }

  handleSelect = (e, el) => {
    const {onChange} = this.props
    onChange && this.props.selected !== el.value && onChange(el.index, el.value)
    this.props.selected !== el.value && this.setState({
      inputActive: false,
      searchValue: el.value,
      isSearch: false
    })
  }
  handleActive = () => !this.state.inputActive && this.setState({inputActive: true})
  handleDeactive = () => this.state.inputActive && this.setState({inputActive: false})
  handleChange = (e) => {
    const {withSearch = false} = this.props
    withSearch && this.setState({
        searchValue: e.target.value,
        inputActive: true,
        isSearch: true
      },
      () => {
        let newData = this.state.staticData.filter((el) => el.value.includes(this.state.searchValue))
        this.setState({data: newData})
      })
  }

  render() {
    const {label, selected, inputActive, data, searchValue} = this.state;
    const {required, classNamesWrap, withSearch = false, t} = this.props;
    let style = {
      width: this.props.width || 570
    }
    return (
      <div
        className={`${cx('select-wrap', 'simpleInput', 'mb0', 'simpleInputBorder', {
          hideBorder: this.props.border0,
          zIndex10: inputActive
        })} ${classNamesWrap ? classNamesWrap : ''}`}
        onClick={this.handleActive}
        onMouseLeave={this.handleDeactive}
      >
        {label && <label>{t(label)} {required && <span>*</span>}</label>}

        <div className={cx('select')}>
          <div className={cx('input-wrap')}>
            <span className={cx('select-arrow', {selectArrowActive: inputActive})}>
              <IconAngle color={'#8c8c8c'} rotate={90} width={4} height={8}/>
            </span>
            <input readOnly={!withSearch} className={cx('select-trigger')}
                   onChange={this.handleChange}
                   value={withSearch ? searchValue : selected}
                   required={required}/>
          </div>
          <div style={style} className={cx('options', 'scroll', {show: inputActive})}>
            {data && data.map((el, index) => {
              return <span key={el.value} onClick={(e) => this.handleSelect(e, el)}
                           className={cx({selected: selected === el.value})}>
                <strong className={cx('arrow')}> <IconAngle color={'#8c8c8c'} width={4} height={8}/></strong>
                 <small>{el.value}</small>
               </span>
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default compose(
  withTranslation(),
)(SelectSearch)
