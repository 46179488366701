import React, {Fragment, memo, useCallback, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {HashLink} from 'components/HashLink'
import {useTranslation} from 'react-i18next';

import LanguageSwitcher from '../LanguageSwitcher/index';
import animatedScroll from 'helpers/animatedScroll';
import IconBurger from 'components/Icons/Burger';
import NavBar from '../Navbar';
import {showSignModal} from 'store/Base/actions'
import {useDispatch, useSelector} from 'react-redux'
import s from './index.module.scss'
import classNames from 'classnames/bind'
import TicketBG from 'components/Icons/TicketBG';
import {getToken} from 'helpers/localStorage';
import {push} from 'connected-react-router';
import {renderLogo} from "../../../../helpers/renderLogo";

const cx = classNames.bind(s)

function Header() {
    const [open, setOpen] = useState(false)
    const [fixed, setFixed] = useState(false)
    const [t] = useTranslation()
    const isAuth = !!getToken()

    const dispatch = useDispatch();

    const onClickCreateEventBtn = useCallback(() => {
        const token = getToken()
        if (token) {
            dispatch(push('/dashboard'))
        } else {
            dispatch(showSignModal({tab: 'login'}))
        }
    }, [dispatch])

    const isLanding = useSelector((state) => state.router.location.pathname === '/organizers')

    useEffect(() => {
        window.onscroll = () => {
            setFixed(window.scrollY > 65)
        }
    }, [])

    const toggleNavBar = () => {
        setOpen(!open)
    }

    return (
        <Fragment>
            <header className={cx('Component', fixed && 'Fixed')}>
                <div className={cx('Container')}>
                    <Link className={cx('Logo')} to='/'>
                        {renderLogo()}
                    </Link>
                    {isLanding && (
                        <nav className={cx('Nav')}>
                            <HashLink
                                scroll={(el) => animatedScroll(null, el)}
                                to="/organizers#how-it-works">
                                {t('landing.howItWorks.title')}
                            </HashLink>
                            <HashLink
                                scroll={(el) => animatedScroll(null, el)}
                                to="/organizers#pricing">
                                {t('landing.pricing.title')}
                            </HashLink>
                            <HashLink
                                scroll={(el) => animatedScroll(null, el)}
                                to="/organizers#advantages">
                                {t('landing.advantages.title')}
                            </HashLink>
                        </nav>
                    )}
                    <div className={cx('Actions')}>
                        <LanguageSwitcher className={cx('LanguageSwitcher')} isShort={false}/>
                        <button
                            onClick={onClickCreateEventBtn}
                            className={cx('TicketButton')}>
                            <TicketBG size={270}/>
                            <span>{isAuth ? t('user.myEvents') : t('event.createEvent')}</span>
                        </button>
                        <button
                            type='button'
                            onClick={toggleNavBar}
                            className={cx('SidebarBtn')}>
                            <IconBurger/>
                        </button>
                    </div>
                </div>
            </header>

            <NavBar isOpen={open} close={toggleNavBar}/>
        </Fragment>
    )
}

export default memo(Header)
