import React, { memo } from 'react'
import classNames from 'classnames/bind'
import s from './Button.module.scss'

const cx = classNames.bind(s)

interface Props {
	disabled?: boolean,
	classNames?: string,
	children?: string,
	callback?: any,
}

const Button: React.FC<Props> = ({ callback, disabled = false, classNames, children }) => (
	<button disabled={disabled}
			onClick={(e) => callback(e)}
			className={cx(classNames ? classNames : '')}
	>{children}</button>
)

export default Button
