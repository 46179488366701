import React, {PureComponent} from 'react'
import PhoneVerificationForm from 'containers/PhoneVerificationForm'
import {SEND_PHONE_AGAIN} from 'apollo/mutations'
import {connect} from 'react-redux'
import {edit} from 'store/Profile/actions'
import {edit as hideModal} from 'store/Base/actions'
import client from 'apollo/'
import IconClose from "../../components/Icons/Close";

class ModalPhoneVerification extends PureComponent {
  componentWillUpdate = (nextProps) => {
    if (nextProps.isOpen && !this.props.isOpen) {
      document.body.style.overflow = 'hidden'
      client.mutate({
        mutation: SEND_PHONE_AGAIN,
        variables: {
          id: this.props.id
        }
      })
    } else {
      document.body.style.overflow = 'auto'
    }
  }

  render() {
    return <div className={`overlay-modal ${this.props.isOpen && 'show'}`}>
      <div className="modal">
        <button onClick={this.props.close} className="close">
         <IconClose />
        </button>
        <div className="modal-main">
          <PhoneVerificationForm
            userId={this.props.id || ''}
            onSuccess={() => {
              this.props.editProfile('isPhoneVerified', true)
              this.props.close()
            }}/>
        </div>
      </div>
    </div>
  }
}

const mapState = ({ Profile, Base}) => ({
  isOpen: Base.showModalPhoneVerification,
  id: Profile.id
})

const mapDispatch = (dispatch) => ({
  editProfile(field, value) {
    dispatch(edit({ field, value }))
  },
  close() {
    dispatch(hideModal({ field: 'showModalPhoneVerification', value: false }))
  }
})

export default connect(mapState, mapDispatch)(ModalPhoneVerification)
