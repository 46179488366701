import 'isomorphic-unfetch'
import {ApolloClient, createHttpLink, from, InMemoryCache} from '@apollo/client';
import {setContext} from "@apollo/client/link/context";
import {onError} from "@apollo/client/link/error";

import store from 'store/'
import {setErrorStatus} from 'store/Base/actions'
import {getToken, logOut} from 'helpers/localStorage'


const errorCodes = onError(({networkError, graphQLErrors}) => {
  const message = graphQLErrors && graphQLErrors[0]?.message
  switch (message) {
    case 'Password was changed after refresh token':
      logOut()
      return;
  }
  if (!networkError) {
    return false
  }
  let status = networkError.statusCode
  if (status === 400) {
    return false
  }
  if (status === 401) {
    logOut()
    return false
  }
  store.dispatch(setErrorStatus(status))
})


const cache = new InMemoryCache()

const httpLink = createHttpLink({
  uri: process.env.NODE_ENV === 'development' ||
  window.location.origin.includes('demo.teeko')
    ? process.env.REACT_APP_DEMO_URL : process.env.REACT_APP_PROD_URL
  // || `https://api.${getSubdomain()}teeko.io/`
})

const authLink = setContext((_, {headers}) => {
  const token = getToken()
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
    }
  }
})


export default new ApolloClient({
  fetchOptions: {fetch},
  link: from([errorCodes, authLink, httpLink]),
  cache
})
