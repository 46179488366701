export default {
  long: {
    ru: 'Russian',
    en: 'English',
    uk: 'Ukrainian',
    he: 'Hebrew',
    pl: 'Polish',
    lv: 'Latvian'
  },
  short: {
    ru: 'Rus',
    en: 'Eng',
    uk: 'Ukr',
    he: 'He',
    pl: 'Pol',
    lv: 'Lv'
  },
  /**
   * remove
   */
  ru: 'Russian',
  en: 'English',
  uk: 'Ukrainian',
  he: 'Hebrew',
  pl: 'Polish',
  lv: 'Latvian'
}