export default {
  up: 'Reģistrācija',
  in:   'Pieteikšanās',
  name:   'Vārds',
  enter: "Pieteikšanās",
  create: 'Izveidot',
  inputEmail: 'Ievadiet e-pasta adresi, kuru izmantojāt reģistrācijas laikā:',
  needHelp: 'Man vajag palīdzību!',
  companyName: 'Kompānijas nosaukums',
  phone:   'Tālruņa numurs',
  password:   'Parole',
  emailOrPhone:   'E-pasts vai tālruņa numurs',
  inUp:   'Pieteikšanās / reģistrācija',
  out:   'Iziet',
  orUp:   'vai reģistrējieties',
  orIn:   'vai piesakieties ar',
  forgot:   'Aizmirsāt paroli?',
  forgotBtn: 'Aizmirsi savu paroli',
  rememberMe: 'Lai mani atcerētos',

  forgotSuccess:   'Ja esat reģistrējies vietnē, jums ir nosūtīts e-pasts ar saiti, lai mainītu paroli',
  email:   'Jūsu e-pasts',
  slogan:   'Pasākumu vadība un biļešu pārdošana, kā tas būtu jādara',
  recovery:   'Atgūšana',
  enterNewPas:   'Ievadiet savu jauno paroli',
  endSign:   'Pilnīga reģistrācija',
  passwordReseted:   'Parole ir veiksmīgi mainīta',
  vereficationMessage:   'Jums ir nosūtīts kods tālruņa numuram verifikācijai',
  iAgreeWith:   'Es piekrītu,',
  serviceTerms:   'lietošanas noteikumi',
  dataProcessing:   'manu personas datu apstrāde',
  createAccount: 'Nav konta? Izveidojiet to',
  loginAccount: 'Jau ir konts? Ievadiet viņu viņu',

  here: 'šeit'
}