import React, {PureComponent} from 'react'
import {SEND_EMAIL_AGAIN, SEND_USER_EMAIL_AGAIN} from 'apollo/mutations';
import {compose} from 'redux'
import client from '../../../apollo'
import {connect} from 'react-redux'
import {Trans, withTranslation} from 'react-i18next';

class EmailNotVerify extends PureComponent {
  state = {
    emailSended: false
  }

  sendEmailAgain = () => {
    client.mutate({
      mutation: SEND_USER_EMAIL_AGAIN,
      variables: {
        id: this.props.userId,
      }
    })
      .then(resp => {
        if (resp.data.sendUserEmailAgain.ok) {
          this.setState({emailSended: true})
        }
      })
  }

  render() {
    return (
      <div>
        <Trans i18nKey='verify.email'/> {"\u0020"}
        {this.state.emailSended
          ? <Trans className='btn-link' value='verify.emailSended'/>
          :
          <button onClick={this.sendEmailAgain} className='btn-link'><Trans i18nKey='verify.sendEmailAgain'/></button>}
      </div>
    )
  }
}

export default compose(
  withTranslation(),
  connect(({Profile}) => ({userId: Profile.id}))
)(EmailNotVerify)
