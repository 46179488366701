export default {
  name: 'Name',
  companyName: 'Company name',
  phone: 'Phone',
  password: 'Password',
  enter: "Sign in",
  create: 'Create',
  emailOrPhone: 'Email or phone',
  inUp: 'Sign in/up',
  up: 'Account creation',
  in: 'Sign in',
  out: 'Logout',
  orUp: 'or sign up with',
  orIn: 'or login with',
  forgot: 'Password recovery',
  forgotBtn: 'Forgot your password',
  rememberMe: 'To remember me',

  inputEmail: 'Enter the e-mail address that you used during registration:',
  needHelp: 'I need help!',
  forgotSuccess: 'If you are registered on the site, you are sent an email with a link to change your password',
  email: 'Your email',
  slogan: 'Event management and ticket sales as it should be',
  recovery: 'Recovery',
  enterNewPas: 'Enter your new password',
  endSign: 'End Registration',
  passwordReseted: 'Password successfully changed',
  vereficationMessage: 'A code has been sent to your phone number for verification',
  iAgreeWith: "I agree with ",
  serviceTerms: "terms of service",
  dataProcessing: "processing my personal data",
  createAccount: 'No account? Create it',
  loginAccount: ' Already have an account? Enter',

  here: 'here'
}