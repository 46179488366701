import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import Notification from './Notification';
import { removeNotification } from 'store/Notifications/actions'
import s from './index.module.scss'

/**
*/

/**
 * Notifications Component - usefull for notify user about changes on page, requests status, etc
 * There is two default notifications, that should check every time
 * Single Notification component should contain this params:
 *
 * @enum Position = 'top-right' | 'top-left' | 'top-center' | 'bottom-right' | 'bottom-left' | 'bottom-center'
 *
 * @param { object } notifications - main data storage
 * @param { Notification{} } notifications[Position] - an object of notifications with notification id as key
 *
 * @typedef Notifications
 * @type { object }
 * @param { number } id - uniquie generated id. Usefull if you want to update notification
 * @param { any } [content=null] - will display inside notification
 * @param { number | false } [autoClose=false] - number in milisecounds or false
 * @param { Position } [position=top-left] - position of notification
 * @param { 'info' | 'success' | 'warning' | 'error' | 'default' } [type=default] - color type of notification
 * @param { boolean } [closeOnClick=false] - close notification on click on it
 * @param { boolean } [hideProgressBar=false] - will display if autoClose != false
 * @param { string } [className=null  ]
 * @param { object } [style=null  ] - in react-style format
 * @callback onClose - fire after closing and removing notification from memory
 * @callback onOpen - fire after displaying notification
 * @callback onUpdate - fire on each update of notification
 */


class Notifications extends PureComponent {
  render() {
    const { notifications, removeNotification } = this.props
    return (
      <div className={`${s.wrapNotification}`}>
        <TopCenter removeNotification={removeNotification} notifications={notifications[`top-center`]} />
        <TopLeft removeNotification={removeNotification} notifications={notifications[`top-left`]} />
        <TopRight removeNotification={removeNotification} notifications={notifications[`top-right`]} />
        <BottomCenter removeNotification={removeNotification} notifications={notifications[`bottom-center`]} />
        <BottomLeft removeNotification={removeNotification} notifications={notifications[`bottom-left`]} />
        <BottomRight removeNotification={removeNotification} notifications={notifications[`bottom-right`]} />
      </div>
    )
  }
}

class TopCenter extends PureComponent {
  render () {
    const { notifications, removeNotification } = this.props
    return (
      <div className={`${s.Notifications} ${s[`Notifications_top-center`]}`}>
        {Object.keys(notifications).map((id, i) => <Notification {...notifications[id]} removeNotification={removeNotification} key={id} />)}
      </div>
    )
  }
}
class TopLeft extends PureComponent {
  render () {
    const { notifications, removeNotification } = this.props
    return (
      <div className={`${s.Notifications} ${s[`Notifications_top-left`]}`}>
        {Object.keys(notifications).map((id, i) => <Notification {...notifications[id]} removeNotification={removeNotification} key={id} />)}
      </div>
    )
  }
}
class TopRight extends PureComponent {
  render () {
    const { notifications, removeNotification } = this.props
    return (
      <div className={`${s.Notifications} ${s[`Notifications_top-right`]}`}>
        {Object.keys(notifications).map((id, i) => <Notification {...notifications[id]} removeNotification={removeNotification} key={id} />)}
      </div>
    )
  }
}
class BottomCenter extends PureComponent {
  render () {
    const { notifications, removeNotification } = this.props
    return (
      <div className={`${s.Notifications} ${s[`Notifications_bottom-center`]}`}>
        {Object.keys(notifications).map((id, i) => <Notification {...notifications[id]} removeNotification={removeNotification} key={id} />)}
      </div>
    )
  }
}
class BottomLeft extends PureComponent {
  render () {
    const { notifications, removeNotification } = this.props
    return (
      <div className={`${s.Notifications} ${s[`Notifications_bottom-left`]}`}>
        {Object.keys(notifications).map((id, i) => <Notification {...notifications[id]} removeNotification={removeNotification} key={id} />)}
      </div>
    )
  }
}
class BottomRight extends PureComponent {
  render () {
    const { notifications, removeNotification } = this.props
    return (
      <div className={`${s.Notifications} ${s[`Notifications_bottom-right`]}`}>
        {Object.keys(notifications).map((id, i) => <Notification {...notifications[id]} removeNotification={removeNotification} key={id} />)}
      </div>
    )
  }
}

const mapState = (state) => ({
  notifications: state.Notifications,
})

const mapDispatch = (dispatch) => ({
  removeNotification (v) {
    dispatch(removeNotification(v))
  }
})

export default connect(mapState, mapDispatch)(Notifications)
