import React, {Fragment} from 'react'
import {Trans, withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {editTicket, setAttendees} from 'store/Event/actions'
import InputNumber from './components/InputNumber'
import {compose} from 'redux'
import formatLanguage from 'helpers/formatLanguage'
import RegistrationForm from '../RegistrationForm'
import classNames from 'classnames/bind'
import s from '../Event.module.scss'
import {CHECK_DISCOUNT_SUM} from 'apollo/queries'
import {cleanPromocode, editPromocode, setDiscounts, setTotal} from '../../../../store/Event/reducer'
import Dots from '../../../../components/common/Dots/Dots'
import {SlideDownIcon} from '../Icons/Icons'
import "../../../../scss/other.scss"
import {addNotification} from "../../../../store/Notifications/actions";
import client from '../../../../apollo'

const cx = classNames.bind(s)

class TicketsForm extends React.PureComponent {
  state = {
    inProp: false,
    promoChanged: []
  }
  getDiscountSum = (id, showError = true) => {
    const {ticketTypes, promocodes} = this.props
    const ticketTypesKey = Object.keys(ticketTypes)
    let promo = ''
    /*check if there is a promo*/
    const hasPromo = (key) => {
      if (promocodes[key]) {
        return promocodes[key].value
      } else {
        return ''
      }
    }
    const withoutFree = ticketTypesKey.some(key => ticketTypes[key].price > 0) /*if price of ticket one or more  */
    if (withoutFree) {
      let obj = []
      ticketTypesKey.forEach(key => {
        obj = [...obj, {
          id: key,
          promocode: hasPromo(key),
          quantity: this.props.ticketTypes[key].selected,
        }]
      })
      client.query({
        query: CHECK_DISCOUNT_SUM,
        variables: {
          slug: this.props.slug,
          data: obj,
        },
        fetchPolicy: 'no-cache',
      }).then(res => {
        const {ticketTypes, total} = res.data.checkDiscounts
        /* generation object in reducer for discounts*/
        let obj = {}
        for (let i = 0; i < ticketTypes.length; i++) {
          let helpObj = {
            [ticketTypes[i].id]: {...ticketTypes[i]},
          }
          obj = {...obj, ...helpObj}
        }
        if (showError && promocodes && promocodes[id] && promocodes[id].value && ticketTypes.some(ticket => +ticket.id === id && +ticket.savings === 0)) {
          this.showNotification()
        }
        this.props.setDiscounts(obj)
        this.props.setTotal(total)
      })
        .catch((error) => alert(error.message))
    }
  }
  showNotification = () => {
    this.props.addNotification({
      type: 'error',
      content: <Trans i18nKey='event.promoIncorrect' tOptions={{lng: this.props.eventLanguage}}/>,
      autoClose: 5000,
    })
  }
  changeNumber = (value, id, ticketFree) => {
    if (value > 0) {
      this.setState({inProp: true})
    } else {
      this.setState({inProp: false})
      
    }
    this.props.editTicket({value, id})
    if (!ticketFree) {
      setTimeout(() => this.getDiscountSum(id), 0)
    }
    if (this.props.hideItem) {
      this.props.setHideItem(false)
    }
    setTimeout(this.props.getTotalSum, 3)
    
  }
  handleDiscount = (id, showError = true) => {
    setTimeout(() => this.getDiscountSum(id, showError), 0)
  }
  
  render() {
    const {ticketTypes} = this.props
    const ticketTypesKeys = Object.keys(ticketTypes)
    const formattedCurrentLng = formatLanguage(this.props.eventLanguage)
    const formattedDefaultLng = this.props.defaultLanguage ? formatLanguage(this.props.defaultLanguage) : ''
    
    const attendeesKeys = Object.keys(this.props.attendees)
    const fillAttendeees = attendeesKeys.filter(key => {
      return this.props.attendees[key].length > 0
    })
    if (!ticketTypesKeys.length) return null
    
    if (ticketTypesKeys.length) {
      return (
        <div className={cx('t-category')}>
          {this.props.keysTicketsForPriority?.map((id) => {
            const ticketType = this.props.ticketTypes[id]
            const isUnlimited = ticketType.availableQuantity === null && ticketType.quantity === null
            const ticketFree = ticketType.price === 0
            const isSelected = ticketType.selected > 0
            const translatedName = !this.props.isMultilanguage ? ticketType?.name : ((ticketType)[`name${formattedCurrentLng}`] || (ticketType)[`name${formattedDefaultLng}`])
            let valuePromo = this.props.promocodes[id] ? this.props.promocodes[id].value : ''
            let currentDiscount = {
              totalPrice: ticketType.price
            }
            let promoSuccess = false;
            let promoError = false
            const attendeeDiscount = this.props.attendeesCheckDiscount[id]
            if (valuePromo && (attendeeDiscount?.savings > 0) && (!attendeeDiscount?.isGroupDiscount)) {
              promoSuccess = true
            } else if (valuePromo && (Number(attendeeDiscount?.savings ?? 0) === 0) && (!attendeeDiscount?.isGroupDiscount)) {
              promoError = true
            }
            if (isSelected) {
              Object.keys(this.props.attendeesCheckDiscount).forEach((key) => {
                if (+key === +id) {
                  currentDiscount = this.props.attendeesCheckDiscount[key]
                }
              })
            }
            let translatedDescription = !this.props.isMultilanguage
              ? ticketType.description
              : ((ticketType)[`description${formattedCurrentLng}`] || (ticketType)[`description${formattedDefaultLng}`])
            
            return (
              
              <div className={cx('t-category')} key={id}> {/* render one ticket type*/}
                <div className={cx('t-header')}>
                  <h2 className={cx('t-title')} onClick={() => this.props.setHideItem(!this.props.hideItem)}>
                    <span>{translatedName}</span>
                    <small className='buying-description' dangerouslySetInnerHTML={{__html: translatedDescription}}/>
                  </h2>
                  {ticketType.isExpired || (ticketType.availableQuantity <= 0 && !isUnlimited)
                    ? <p className={cx('ticket_is_empty', 'promo')}>
                      <div className={cx('line')}>
                        <Dots tickets/>
                      </div>
                      <p><Trans i18nKey='event.ticket.end'/></p>
                    </p>
                    : <div className={cx('wrap-count')}>
                      <InputNumber
                        value={ticketType.selected}
                        classNames={isSelected ? 'active' : ''}
                        onChange={(value) => this.changeNumber(value, id, ticketFree)}
                        onBlur={this.props.setAttendees}
                        max={ticketType.availableQuantity || 100 /** our personal limit, for avoid crashing of the page */}/>
                      
                      <div className={cx('price')}>
                        {ticketType.price === 0
                          ? <span className="price"><Trans i18nKey='event.ticket.free'/></span>
                          : <Fragment>
											<span>{+currentDiscount.totalPrice} <small>{this.props.currency}</small>
											</span>
                          </Fragment>}
                        {+ticketType.availableQuantity < 5 && !isUnlimited && <div className={'limit-text'}>
                          <span className="tickets-left">
                            <Trans i18nKey='event.ticket.remainingTickets'/>
                            <strong>{+ticketType.availableQuantity}</strong>
                          </span>
                        </div>}
                      </div>
                      {ticketType.withPromocode && ticketType.selected > 0  && +ticketType.price  > 0?
                        <PromoItem
                          editPromo={this.props.editPromo}
                          valuePromo={valuePromo}
                          promoError={promoError}
                          promoSuccess={promoSuccess}
                          id={id}
                          isSelected={isSelected}
                          handleDiscount={this.handleDiscount}
                        /> :
                        
                        <div className={cx('promo')}>
                          <div className={cx('line')}>
                            <Dots tickets/>
                          </div>
                          <div className={cx('promo-item')}>
                          </div>
                        </div>
                      }
                    </div>
                  }
                </div>
                <div className={cx('wrap-items', {showRegistrationForm: this.props.hideItem})}>
                  <div className={`${cx('items')}`}>
                    <RegistrationForm ticketType={ticketType} eventID={this.props.eventID}
                                      currentDiscount={currentDiscount}
                                      lng={this.props.eventLanguage}
                                      isPublished={this.props.isPublished}
                                      isMultilanguage={this.props.isMultilanguage}
                                      defaultLanguage={this.props.defaultLanguage}
                                      getDiscountSum={this.getDiscountSum}
                                      getTotalSum={this.props.getTotalSum}
                                      id={id}
                                      currency={this.props.currency}/>
                  </div>
                
                
                </div>
                
                {!this.props.hideItem && fillAttendeees.some(a => +a === ticketType.oldId) &&
                <button className={cx('slide-down')}
                        onClick={() => this.props.setHideItem(true)}>
                  <SlideDownIcon/>
                </button>
                }
              </div>
            )
          })}
        </div>
      )
    }
  }
}

const mapState = ({Event}) => ({
  ticketTypes: Event.ticketTypes,
  attendees: Event.attendees,
  attendeesCheckDiscount: Event.attendeesCheckDiscount,
  promocodes: Event.promocodes,
})

const mapDisaptch = (dispatch) => ({
  setTotal(value) {
    dispatch(setTotal(value))
  },
  setDiscounts(discounts) {
    dispatch(setDiscounts(discounts))
  },
  setAttendees() {
    dispatch(setAttendees())
  },
  editTicket(payload) {
    dispatch(editTicket(payload))
  },
  editPromo(value, ticketId) {
    dispatch(editPromocode(value, ticketId))
  },
  cleanPromocode() {
    dispatch(cleanPromocode())
  },
  addNotification(config) {
    dispatch(addNotification(config))
  }
})

export default compose(
  connect(mapState, mapDisaptch),
  withTranslation(),
)(TicketsForm)


const PromoItem = ({valuePromo, id, editPromo, handleDiscount, isSelected, promoSuccess, promoError}) => {
  return <div className={cx('promo')}>
    <div className={cx('line')}>
      <Dots tickets/>
    </div>
    <div className={cx('promo-item')}>
      <p><Trans i18nKey='event.ticket.promocode'/></p>
      <div
        className={cx('input-wrap', 'Component', {
          selected: isSelected,
          promoSuccess: promoSuccess,
          promoError: promoError
        })}>
        <input
          className={cx(
            'input-item',
            'Input',
            // 'InputPromo'
          )}
          value={valuePromo}
          onBlur={(e) => handleDiscount(id)}
          onChange={e => {
            editPromo(e.currentTarget.value, id)
            handleDiscount(id, false)
            
          }}
        />
      </div>
    </div>
  </div>
}
