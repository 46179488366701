export default {
  'Enter': 'Ievadiet',
  
  profile: 'Profils',
  account: 'Mans Konts',

  general: 'Vispārīgi',
  finances: 'Finanses',
  infoFull: 'Jūsu konts ir pilns',
  infoNotFull: 'Jūsu konts nav pilnīgs',
  editData: 'Rediģēt datus',
  cashWithdrawal: 'Skaidras naudas izņemšana',
  withdrawMoney: 'Izņemt naudu',
  uploadImageDescription: 'Lejupielādējiet skenētas dokumentu kopijas (pase, identifikācijas numurs, izraksti no vienotā reģistra, bankas sertifikāts par konta atvēršanu)',
  uploadImageDescriptionNatural: 'Lejupielādējiet skenētas dokumentu kopijas (pase, identifikācijas numurs)',
  uploadImageFormat: 'Failiem jābūt formātos: jpeg, jpg, pdf',
  uploadImageDetail1: 'pases skenēšana no 1. līdz 11. lapai, ieskaitot reģistrācijas lapu un nākamo tukšo vietu (lai finanšu apstrāde varētu redzēt, ka reģistrācija skenēs ir visjaunākā)',
  uploadImageDetail2: 'skenējumiem jābūt izplatītiem (2 lappuses vienā skenēšanā)',
  uploadImageDetail3: 'iekšējās ID kartes skenēšana, kurai pases vietā jābūt abās pusēs',
  cashDescription: 'Jūs varat izņemt naudu esošajiem kontiem vai izveidot jaunu, noklikšķinot uz pogas “pievienot jaunu kontu”',
  addNewWayPay: 'Pievienojiet jaunu maksājuma veidu',
  personInfo: 'Personīgā informācija',
  editDescription: 'Lauki, kas apzīmēti ar zvaigznīti, ir obligāti.',
  dragInDrop: 'Velciet failu šeit vai',
  dragInDropImage: 'Velciet attēlu šeit vai',

  uploadFileWithPC: 'lejupielādēt no datora',
  reset: 'Atiestatīt',
  refresh: 'Atjaunot',
  scanType: {
    PASSPORT: 'Pase',
    PASSPORT_PE: 'Individuāla uzņēmēja pases kopija (visas izplatās tur, kur ir ieraksti, un reģistrācijas izplatība, pat ja tās tur nav)',
    PASSPORT_OOO: 'Juridiskas personas direktora pases (1.-6. Lpp., Reģistrācija un nākamā lapa pēc tās)',
    PASSPORT_OO: 'Juridiskas personas direktora pases (1.-6. Lpp., Reģistrācija un nākamā lapa pēc tās)',

    INN: 'Identifikācijas numurs',
    INN_PE: 'FLP TIN kopija',
    INN_OOO: 'Juridiskas personas direktora TIN sejas',
    INN_OO: 'Juridiskas personas direktora TIN sejas',

    REGISTRY_REPORT_PE: 'Izraksts no vienotās valsts Reģistrācija / sertifikāts par individuālā uzņēmēja valsts reģistrāciju',
    REGISTRY_REPORT_OOO: 'Izraksti no USR / valsts sertifikāta. juridiskas personas reģistrācija',
    REGISTRY_REPORT_OO: 'Izraksti no USR / valsts sertifikāta. juridiskas personas reģistrācija',

    BANK_REPORT_PE: 'Bankas sertifikāts par norēķinu konta atvēršanu',
    BANK_REPORT_OOO: 'Norēķinu konta atvēršanas sertifikāts bankā (jauns paraugs ar norādi IBAN)',
    BANK_REPORT_OO: 'Norēķinu konta atvēršanas sertifikāts bankā (jauns paraugs ar norādi IBAN)',

    COMPANY_STATUTE_OOO: 'No juridisko personu hartas sejas',
    COMPANY_STATUTE_OO: 'No juridisko personu hartas sejas',
    ORG_STRUCTURE_OOO: 'Īpašumtiesību struktūra',
    ORG_STRUCTURE_OO: 'Īpašumtiesību struktūra',

    DIRECTOR_PROTOCOL_OOO: 'Protokols, kā arī rīkojums par direktora iecelšanu',
    DIRECTOR_PROTOCOL_OO: 'Protokols, kā arī rīkojums par direktora iecelšanu',
    FINANCE_REPORT_OO: 'Gada finanšu pārskats',
    FONDY_CONTRACT: '',
  },

  verificationType: {
    PENDING: 'notiek pārbaude',
    CANCELED: 'nav apstiprināts',
    VERIFIED: 'apstiprināja'
  },
  finance: {
    PENDING: 'Konts tiek pārbaudīts, lūdzu, uzgaidiet',
    CANCELED: 'Konts nav pieņemts, lūdzu, sazinieties ar atbalsta dienestu',
    VERIFIED: 'Konts ir pieņemts',
    rs: 'p / s',
    paymentOnАccount: 'Maksājums p / s',
    paymentOnCard: 'Maksājums kartē',
    companyName: 'Maksājumu uzņēmuma nosaukums',
    mfo: 'MFI',
    edrpou: 'EDRPOU',
    creditCard: 'Kredītkarte',
    bankAccountNumber: 'Bankas konta numurs',
    uploadPassport: 'Lejupielādēt pases skenēšanu',
    uploadId: 'Lejupielādēt skenēšanas ID. numuri ',
    cardNumber: 'Kartes numurs',
    uploadContract: 'Lejupielādēt skenēšanas parakstīto līgumu ar fondi',
    naturalPersons: 'Fiziskā persona',
    naturalPersonsFull: 'Individuāls',
    privateEntrepreneurFull: 'Uzņēmējs',
    oooFull: 'Sabiedrība ar ierobežotu atbildību',
    ooFull: 'Sabiedriskā organizācija',
    privateEntrepreneur: 'FLP',
    ooo: 'SIA',
    iban: 'IBAN',
    oo: 'OP',

    ltd: 'Ltd',
    statement: 'Skenēt izrakstus no viena reģistra',
    sertificateFromBank: 'Palīdzība no bankas, lai atvērtu kontu',
  },
  addNewFinance: 'Pievienot jaunu kontu',
  email: 'E-pasts',
  firstName: 'Vārds',
  lastName: 'Vārds',
  phone: 'Tālruņa numurs',
  birthday: 'Dzimšanas datums',
  changePassword: 'Mainīt paroli',
  oldPassword: 'Vecā parole',
  newPassword: 'Jauna parole',
  passwordChanged: 'Parole ir veiksmīgi mainīta.',
  myEvents: 'Mani notikumi'
}
