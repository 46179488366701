export default {
  long: {
    ru: 'Krievu',
    en: 'Angļu ',
    uk: 'Ukrainas',
    he: 'Ebreju ',
    pl: 'Poļu valoda',
    lv: 'Latviešu'
  },
  short: {
    ru: 'Rus',
    en: 'Ang',
    uk: 'Ukr',
    he: 'Ivr',
    pl: 'Pāvils',
    lv: 'Lat'
  },
  /**
   * remove
   */
  'ru': 'Русский',
  'en': 'Английский',
  'uk': 'Украинский',
  'he': 'Иврит',
  'pl': 'Польский',
  'lv': 'Латвийский'
}