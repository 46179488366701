import {gql} from '@apollo/client'

export const ALL_EVENTS_FROM = gql`
    query AllEventsFrom($alias: String!) {
        allEventsFrom (alias: $alias) {
            shortDescription
            id
            slug
            currency
            begins
            image
            timezoneField
            defaultLanguage
            isMultilanguage
            isOnline
            name
            nameEn
            nameRu
            nameUk
            nameHe
            namePl
            nameLv

            locationName
            locationNameEn
            locationNameRu
            locationNameUk
            locationNameHe
            locationNamePl
            locationNameLv

            address
            prices
        }
    }
`

export const SEARCH_SLUG = gql`
    query ($slug: String) {
        searchSlug(slug: $slug){
            exists
        }
    }
`
export const MY_GROUP_DISCOUNT = gql`
    query ($eventId: ID!){
        myGroupDiscount(eventId: $eventId){
            name
            description
            begins
            ends
            discountPercent
            discountFixed
            ticketsFrom
            ticketsTo
            ticketsToUnlimited
            unlimitedTime
            ticketTypes {
                color
            }

        }
    }
`
export const PROMOCODES = gql`
    query($eventId: ID) {
        promocodes(eventId: $eventId){
            id
            code
            activations
            couponType {
                id
                prefix
                validFrom
                validTo
                discountType
                type
                discount
                unlimited
                ticketTypes{
                    id
                    name
                    priority
                    color
                    event{
                        currency
                    }
                }
            }
        }
    }
`
export const CHANNELS_STATISTICS = gql`
    query($eventId: ID!){
        channelsStatistics(eventId: $eventId){
            channels{
                name
                tickets
                byTicket {
                    name
                    quantity
                }
            }
        }
    }
`
export const CHECK_DISCOUNT_SUM = gql`
    query CheckDiscountsSum($slug: String, $data: [DiscountCheckInputType]){
        checkDiscounts(slug: $slug, data: $data){
            total,
            ticketTypes
            {
                id
                totalPrice
                savings
                isGroupDiscount
            }
        }
    }
`

export const ME = gql`
    query {
        me {
            id
            isSuperuser
            email
            firstName
            lastName
            phone
            birthday
            isEmailVerified
            isPhoneVerified
            avatar
            alias
        }
    }
`

export const FINANCES = gql`
    query {
        finances {
            id
            status
            name
            companyName
            isEnabled
            merchantType
            creditCard
            mfo
            edrpou
            bankAccountNumber
            events {
                ends
                id
            }
            scans {
                id
                scanType
                file
                isApproved
            }
        }
    }
`

export const EVENTS = gql`
    query Events($filter: EventsFilterEnum) {
        events (filter: $filter) {
            id
            myRole,
            isOwner,
            user{
                firstName
                lastName
            }
            isOnline
            totalTickets
            totalIncomeWithoutFee
            totalIncomeWithFee
            name
            isPublished
            timezoneField
            image
            defaultLanguage
            isMultilanguage
            slug
            begins
            currency
            locationName
            locationNameRu
            locationNameUk
            locationNameEn
            locationNameHe
            locationNamePl
            locationNameLv
            address
            description
            descriptionEn
            descriptionRu
            descriptionUk
            descriptionHe
            descriptionPl
            descriptionLv
            isUnlimited
            organizers{
                logo
                user {
                    firstName
                }
            }
        }
    }
`

export const FEEDBACKS = gql`
    query {
        feedbacks {
            text
            id
            name
            facebook
            avatar
            company
        }
    }
`

export const ORGANIZERS = gql`
    query {
        organizers {
            id
            name
            email
            phone
            specialization
            logo
            facebook
            telegram
            additionalPhone
            additionalContact
        }
    }
`

export const ORGANIZER = gql`
    query ($id: ID!) {
        organizer (id: $id) {
            id
            logo
            name
            specialization
            email
            phone
            telegram
            facebook
            additionalPhone
            additionalContact
        }
    }
`

export const TKSOURCE = gql`
    query ($event: ID!) {
        attendees (event: $event) {
            id
            tksource
        }
    }
`

export const ATTENDEES = gql`
    query ($event: ID, $page: Int, $limit: Int, $search: String) {
        attendees (event: $event, page: $page,limit: $limit, search: $search) {
            attendees { tksource
                created
                id
                ticketName
                ticketTypePrice
                ticketType {
                    id
                }
                finalPrice: viewedPrice
                code
                firstName
                lastName
                email
                phone
                status
                paymentType
                coupon {
                    id
                    code
                }
                order {
                    id
                }
                answerSet {
                    id
                    answerText
                    question {
                        id
                        name
                        isDefault
                    }
                }
                discount
                discountType
            }
            meta {
                pagesTotal
                page
                limit
                itemsTotal
            }
        }

    }
`

export const ATTENDEES_SEARCH = gql`
    query ($eventId: ID!, $searchText: String!) {
        attendeeSearch (eventId: $eventId, searchText: $searchText) {
            tksource
            created
            id
            ticketName
            ticketTypePrice
            ticketType {
                id
            }
            finalPrice
            code
            firstName
            lastName
            email
            phone
            status
            coupon {
                id
                code
            }
            order {
                id
            }
            answerSet {
                id
                answerText
                question {
                    id
                    name
                    isDefault
                }
            }
            discount
            discountType
        }
    }
`

export const MULTILANGUAGE_NAME_EVENT_FIELDS = `
  name
  nameEn
  nameRu
  nameUk
  nameHe
  namePl
  nameLv
`
export const MULTILANGUAGE_RAW_DESCRIPTION_EVENT_FIELDS = `
  rawDescription
  rawDescriptionEn
  rawDescriptionRu
  rawDescriptionUk
  rawDescriptionHe
  rawDescriptionPl
  rawDescriptionLv
`
export const MULTILANGUAGE_DESCRIPTION_EVENT_FIELDS = `
  description
  descriptionEn
  descriptionRu
  descriptionUk
  descriptionHe
  descriptionPl
  descriptionLv
`
export const MULTILANGUAGE_LOCATION_NAME_EVENT_FIELDS = `
  locationName
  locationNameEn
  locationNameRu
  locationNameUk
  locationNameHe
  locationNamePl
  locationNameLv
`
export const MULTILANGUAGE_LOCATION_DESCRIPTION_EVENT_FIELDS = `
  locationDescription
  locationDescriptionEn
  locationDescriptionRu
  locationDescriptionUk
  locationDescriptionHe
  locationDescriptionPl
  locationDescriptionLv
`

export const EVENT = gql`
    query ($slug: String!) {
        event (slug: $slug) {
            id
            slug
            begins
            ends
            image
            isApproved
            isHidden
            isPublished
            timezoneField
            googleAnalytics
            hasFinances
            currency
            isOnline
            defaultLanguage
            isMultilanguage
            availableLanguages
            ${MULTILANGUAGE_NAME_EVENT_FIELDS}
            ${MULTILANGUAGE_DESCRIPTION_EVENT_FIELDS}
            ${MULTILANGUAGE_LOCATION_NAME_EVENT_FIELDS}
            ${MULTILANGUAGE_LOCATION_DESCRIPTION_EVENT_FIELDS}
            finances {
                name
                id
                status
                merchantType
            }
            address
            position
            pixelScript
            isDarkEnabled
            myRole
            isOwner
            organizers {
                name
                id
            }
            days {
                id
                ends
                begins
                eventId
                location {
                    properties{
                        name
                        address
                        description
                        position
                        ${MULTILANGUAGE_NAME_EVENT_FIELDS}
                        ${MULTILANGUAGE_DESCRIPTION_EVENT_FIELDS}
                    }
                }
            }
            ticketTypes {
                color
                id
                ${MULTILANGUAGE_NAME_EVENT_FIELDS}
                ${MULTILANGUAGE_RAW_DESCRIPTION_EVENT_FIELDS}
                ${MULTILANGUAGE_DESCRIPTION_EVENT_FIELDS}
                isCustomTicketLetterEnabled,
                customTicketLetterSubject,
                customTicketLetter,
                price
                quantity
                begins
                ends
                isUnlimited,
                isAwaitingApproval,
                simplePay,
                questions {
                    name
                    id
                    questionType
                    options
                    helpText
                    priority
                    isDefault
                    isRequired
                    nameEn
                    nameRu
                    nameUk
                    nameHe
                    nameLv
                    namePl
                    optionsEn
                    optionsRu
                    optionsUk
                    optionsHe
                    optionsPl
                    optionsLv
                    helpTextEn
                    helpTextRu
                    helpTextUk
                    helpTextPl
                    helpTextHe
                    helpTextLv

                }
            }
        }
    }
`

export const EVENT_FINANCE = gql`
    query ($slug: String!) {
        event (slug: $slug) {
            id,
            finances {
                id
            },
        }
    }
`

export const ACL_SET = gql`
    query ($slug: String!) {
        event (slug: $slug) {
            id
            aclSet {
                id
                created
                role
                status
                user {
                    email
                    phone
                }
                email
                phone

            }
        }
    }
`

export const EVENT_STATISTIC = gql`
    query ($slug: String!) {
        event (slug: $slug) {
            totalTickets
            totalIncomeWithoutFee
            totalOfflineIncomeWithFee
            totalIncomeWithFee
            currency
            id
        }
    }
`
export const EVENT_PAGE = gql`
    query ($slug: String!) {
        eventPage (slug: $slug) {
            isTeam
            canPublish
            event {
                icalLink
                gcalLink
                isOnline
                defaultLanguage
                isMultilanguage
                availableLanguages
                ${MULTILANGUAGE_NAME_EVENT_FIELDS}
                ${MULTILANGUAGE_DESCRIPTION_EVENT_FIELDS}
                ${MULTILANGUAGE_LOCATION_NAME_EVENT_FIELDS}
                ${MULTILANGUAGE_LOCATION_DESCRIPTION_EVENT_FIELDS}
                ${MULTILANGUAGE_RAW_DESCRIPTION_EVENT_FIELDS}
                id,
                slug,
                isTosDisplay,
                timezoneField,
                isPublished,
                googleAnalytics
                currency

                organizers {
                    edges{
                        node{
                            logo
                            name
                            id
                            email
                            phone
                            specialization
                            facebook
                            telegram
                        }
                    }
                },
                name
                isFooterDisplay
                begins
                ends
                image
                description
                isDarkEnabled
                locationName
                locationDescription
                defaultLanguage
                address
                position {
                    coordinates
                }
                googleAnalytics
                googleAnalyticsCode
                pixelScript
                pixelId
                ticketTypesOrder {
                    oldId

                    name
                    nameEn
                    nameRu
                    nameUk
                    nameHe
                    namePl
                    nameLv
                    description
                    descriptionEn
                    descriptionRu
                    descriptionUk
                    descriptionHe
                    descriptionPl
                    descriptionLv
                    priority
                    price
                    simplePay
                    quantity
                    availableQuantity
                    begins
                    ends
                    withPromocode
                    questionsOrder {
                        name
                        nameEn
                        nameRu
                        nameUk
                        nameHe
                        namePl
                        nameLv

                        options
                        optionsEn
                        optionsRu
                        optionsUk
                        optionsHe
                        optionsPl
                        optionsLv

                        helpText
                        helpTextEn
                        helpTextRu
                        helpTextUk
                        helpTextHe
                        helpTextPl
                        helpTextLv

                        oldId
                        questionType
                        priority
                        isRequired
                        isDefault
                    }
                }
            }
        }
    }
`
export const FULL_EVENT = gql`
    query ($slug: String!) {
        orderEvent (slug: $slug) {
            defaultLanguage
            isMultilanguage
            availableLanguages
            ${MULTILANGUAGE_NAME_EVENT_FIELDS}
            ${MULTILANGUAGE_DESCRIPTION_EVENT_FIELDS}
            ${MULTILANGUAGE_LOCATION_NAME_EVENT_FIELDS}
            ${MULTILANGUAGE_LOCATION_DESCRIPTION_EVENT_FIELDS}

            id,
            slug,
            isTosDisplay,
            timezoneField,
            isPublished,
            googleAnalytics
            currency

            organizers {
                edges{
                    node{
                        logo
                        name
                        id
                        email
                        phone
                        specialization
                        facebook
                        telegram
                    }
                }
            },
            name
            isFooterDisplay
            begins
            ends
            image
            description
            isDarkEnabled
            locationName
            locationDescription
            defaultLanguage
            address
            position {
                coordinates
            }
            googleAnalytics
            googleAnalyticsCode
            pixelScript
            pixelId
            ticketTypesOrder {
                oldId

                name
                nameEn
                nameRu
                nameUk
                nameHe
                namePl
                nameLv
                description
                descriptionEn
                descriptionRu
                descriptionUk
                descriptionHe
                descriptionPl
                descriptionLv
                priority
                price
                simplePay
                quantity
                availableQuantity
                begins
                ends
                withPromocode
                questionsOrder {
                    name
                    nameEn
                    nameRu
                    nameUk
                    nameHe
                    namePl
                    nameLv

                    options
                    optionsEn
                    optionsRu
                    optionsUk
                    optionsHe
                    optionsPl
                    optionsLv

                    helpText
                    helpTextEn
                    helpTextRu
                    helpTextUk
                    helpTextHe
                    helpTextPl
                    helpTextLv

                    oldId
                    questionType
                    priority
                    isRequired
                    isDefault
                }
            }
        }
    }
`

export const TICKETS = gql`
    query ($eventId: ID) {
        ticketTypes(eventId: $eventId) {
            id
            name
            nameEn
            nameRu
            nameUk
            nameHe
            namePl
            nameLv
            description
            descriptionEn
            descriptionRu
            descriptionUk
            descriptionHe
            descriptionPl
            descriptionLv
            isCustomTicketLetterEnabled
            customTicketLetterSubject
            customTicketLetter
            price
            quantity
            isUnlimited
            color
            availableQuantity
            soldQuantity

            isAwaitingApproval
            simplePay

            begins
            ends

            couponTypes {
                id
                prefix
                type
                validFrom
                validTo
                discountType
                discount
                activations
                unlimited
                coupons {
                    id
                    code
                }
            }
        }
    }
`

export const FINANCE = gql`
    query ($id: ID!) {
        finance (id: $id) {
            id,
            creditCard
            mfo
            merchantType
            edrpou
            name
            bankAccountNumber
            iban
            scans {
                id
                file
                isApproved
                scanType
            }
        }
    }
`

export const TICKETS_WITH_QUESTIONS = gql`
    query ($eventId: ID) {
        ticketTypes(eventId: $eventId) {
            id
            name
            nameEn
            nameRu
            nameUk
            nameHe
            namePl
            nameLv
            questions {
                id
                name
                nameEn
                nameRu
                nameUk
                nameHe
                namePl
                nameLv

                options
                optionsEn
                optionsRu
                optionsUk
                optionsHe
                optionsPl
                optionsLv

                helpText
                helpTextEn
                helpTextRu
                helpTextUk
                helpTextHe
                helpTextPl
                helpTextLv

                questionType
                priority
                isRequired
                helpText
                isDefault
            }
        }
    }
`

export const ORDER_STATUS = gql`
    query ($orderId: UUID) {
        orderStatus (orderId: $orderId)
    }
`

export const GET_ANSWERS_EXPORT_URL = gql`
    query ($eventId: ID!, $format: FormatEnum!) {
        getAnswersExportUrl (eventId: $eventId, format: $format)
    }
`

export const CHECK_SIGN_UP_TOKEN = gql`
    query ($token: String!) {
        checkInvitataionToken(token: $token) {
            id
            email
            phone
        }
    }
`
