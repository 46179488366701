import React, { PureComponent } from 'react'
import s from './index.module.scss'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next';

/**
 * @description
 * Main `Button` component.
 * @param text - will translate with locale files.
 * @param color - color schema -- background+text
 * @param prevent - helper to dont write `event.preventDefault()`
 * @param className - additional class name
 * @param id - html5 attribute
 * @param textTransform - creates a className
 * @param isLink - if link - use `<Link>` instead `<button>`. If `true` - you can pass all `Link` props, like `to`
 * 
 * @callback onClick - default callback
 * @param e - event will pass all time
 * 
 * @typedef additionalProps - HTML 5 attributes for button. `type` for example
 */
class Button extends PureComponent {
  static propTypes = {
    text: PropTypes.string,
    color: PropTypes.oneOf([
      'primary', 'primary-outline', 'primary-light',
      'yellow', 'yellow-outline',
    ]),
    onClick: PropTypes.func,
    prevent: PropTypes.bool,
    isLink: PropTypes.bool,
    className: PropTypes.string,
    id: PropTypes.string,
    additionalProps: PropTypes.object,
    textTransform: PropTypes.oneOf(['uppercase', 'lowercase', 'capitalize']),
  }

  static defaultProps = {
    color: 'primary',
    textTransform: 'uppercase',
    prevent: true,
    disabled: false
  }

  onClick = e => {
    const { onClick, prevent ,disabled} = this.props
    prevent && e.preventDefault()
    onClick && onClick(e)
  }

  render() {
    const { 
      text, color, children,
      className, id, additionalProps,
      textTransform, isLink, prevent, type,
      ...props
    } = this.props

    if (isLink) {
      return (
        <Link
          {...props}
          {...additionalProps}
          className={`${s.Component} ${s[`Color-${color}`]} ${s[`TextTransform-${textTransform}`]} ${className || ''}`}
          id={id}>
        {children || this.props.t(text)}
        </Link>
      )
    }

    return (
      <button
        className={`${s.Component} ${s[`Color-${color}`]} ${s[`TextTransform-${textTransform}`]} ${className || ''}`}
        id={id}
        disabled={this.props.disabled}
        type={type || 'button'}
        onClick={this.onClick}
        {...additionalProps}>
        {children || this.props.t(text)}
      </button>
    )
  }
}


export default withTranslation()(Button)