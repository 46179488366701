export default {
  up: 'Создание аккаунта',
  in: 'Выполните вход',
  name: 'Имя',
  companyName: 'Название компании',
  enter: "Вход",
  create: 'Создать',
  phone: 'Номер телефона',
  password: 'Пароль',
  emailOrPhone: 'Email или номер телефона',
  inUp: 'Вход/регистрация',
  out: 'Выход',
  rememberMe: 'Запомнить меня',
  orUp: 'Или зарегистрируйтесь с',
  orIn: 'Или войдите с помощью',
  forgot: 'Восстановление пароля',
  forgotBtn: 'Забыли пароль',
  inputEmail: 'Введите e-mail адрес который вы использовали при регистрации:',
  needHelp: 'Мне нужна помощь!',
  forgotSuccess: 'Если вы зарегистрированы на сайте, вам на почту выслано письмо со ссылкой для смены пароля',
  email: 'Ваш email',
  slogan: 'Управление событиями и продажа билетов, как и должно быть',
  recovery: 'Восстановление',
  enterNewPas: 'Введите ваш новый пароль',
  endSign: 'Закончить регистрацию',
  passwordReseted: 'Пароль успешно изменен',
  vereficationMessage: 'Вам выслан  код на номер телефона для верификации',
  iAgreeWith: "Я соглашаюсь с ",
  serviceTerms: "условиями использования и политикой конфиденциальности",
  dataProcessing: "обработкой моих персональных данных",
  createAccount: 'Нет аккаунта? Создайте его',
  loginAccount: ' Уже есть аккаунт? Войдите в него',
  here: 'здесь'
}
