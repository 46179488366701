import React, {Suspense} from 'react'
import Preloader from 'components/Preloader';
import Main from './Main'
import ErrorBoundary from 'components/ErrorBoundary'

// const Main = lazy(() => import('./Main'))
const AsyncRoot = () =>
  <ErrorBoundary>
    <Suspense fallback={<Preloader/>}>
      <Main/>
    </Suspense>
  </ErrorBoundary>

export default AsyncRoot
