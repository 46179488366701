import React, { PureComponent } from 'react'

export default class IconClose extends PureComponent {
  render() {
    return (
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 0C4.92339 0 0 4.92339 0 11C0 17.0766 4.92339 22 11 22C17.0766 22 22 17.0766 22 11C22 4.92339 17.0766 0 11 0ZM16.3935 13.8875C16.602 14.096 16.602 14.4331 16.3935 14.6415L14.6371 16.3935C14.4286 16.602 14.0915 16.602 13.8831 16.3935L11 13.4839L8.1125 16.3935C7.90403 16.602 7.56693 16.602 7.35847 16.3935L5.60645 14.6371C5.39798 14.4286 5.39798 14.0915 5.60645 13.8831L8.51613 11L5.60645 8.1125C5.39798 7.90403 5.39798 7.56693 5.60645 7.35847L7.3629 5.60202C7.57137 5.39355 7.90847 5.39355 8.11694 5.60202L11 8.51613L13.8875 5.60645C14.096 5.39798 14.4331 5.39798 14.6415 5.60645L16.398 7.3629C16.6065 7.57137 16.6065 7.90847 16.398 8.11694L13.4839 11L16.3935 13.8875Z" fill="var(--accent-color)"/>
      </svg>
    )
  }
}
