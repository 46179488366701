import ActionTypes from "./actionTypes";

const initialState = {
  modalShow: false,

  isGenerateCoupon: false,
  validFrom: null,
  validTo: null,
  discountType: '',
  discount: '',
  code: '',
  activations: '0',
  unlimited: false,
  ticketTypes: [],
  quantityPromo: null,
  couponId: null,

  discountId: null,
  name: '',
  description: '',
  isActive: false,
  unlimitedTime: false,
  begins: '',
  ends: '',
  discountPercent: '',
  discountFixed: '',
  ticketsFrom: '',
  ticketsTo: '',
  ticketsToUnlimited: false,
  ticketTypeIds: []
}
export const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_PROMO:
      return {...state, ...action.promo}
      break;
    case ActionTypes.EDIT_PROMO:
      if (state.validTo && (action.field === "validFrom" && (+action.value > +state.validTo))) {
        return {
          ...state,
          [action.field]: action.value,
          validTo: action.value.plus({days: 1})
        }
      }
      if (state.begins && (action.field === "begins" && (+action.value > +state.begins))) {
        return {
          ...state,
          [action.field]: action.value,
          ends: action.value.plus({days: 1})
        }
      }
      if (state.validFrom && (action.field === "validTo" && (+action.value < +state.validFrom))) {
        return {
          ...state,
          [action.field]: action.value,
          validFrom: action.value.minus({days: 1})
        }
      }
      if (state.begins && (action.field === "ends" && (+action.value < +state.begins))) {
        return {
          ...state,
          [action.field]: action.value,
          begins: action.value.minus({days: 1})
        }
      }
      return {
        ...state,
        [action.field]: action.value
      }
      break;
    case ActionTypes.TICKET_TYPES_CHECKED:
      if (action.types === "promo") {
        if (action.all.length > 0) {
          if (state.selectAllTickets) {
            return {...state, selectAllTickets: false}
          } else {
            return {...state, ticketTypes: action.all, selectAllTickets: true}
          }
        }
        if(action.onlyOne) {
          return  {...state, ticketTypes: [action.id]}
        }
        return {
          ...state,
          selectAllTickets: false,
          ticketTypes:
            state.ticketTypes.some(id => id === action.id)
              ? state.ticketTypes.filter(el => el !== action.id)
              : [...state.ticketTypes, action.id]
        }
      }
      if (action.types === "discount") {
        if (action.all.length > 0) {
          if (state.selectAllTickets) {
            return {...state, selectAllTickets: false}
          } else {
            return {...state, ticketTypeIds: action.all, selectAllTickets: true}
          }
        }

        return {
          ...state,
          selectAllTickets: false,
          ticketTypeIds:
            state.ticketTypeIds.some(id => id === action.id)
              ? state.ticketTypeIds.filter(el => el !== action.id)
              : [...state.ticketTypeIds, action.id]
        }
      }
      break;
    case ActionTypes.RESET:
      return initialState
      break;
    default:
      return state;
  }
}