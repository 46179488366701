import React from 'react'

export default function Logo() {
  return (
    // <svg width="138" height="34" viewBox="0 0 138 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <rect width="17.4143" height="29.3177" rx="6.61301"
    //         transform="matrix(-0.707107 0.707107 0.707107 0.707107 12.7197 0.406738)" fill="#E5663B"/>
    //   <path fillRule="evenodd" clipRule="evenodd"
    //         d="M30.4982 3.78732C29.6739 2.9862 28.3562 3.00502 27.5551 3.82935L10.3326 21.5509C9.53149 22.3752 9.55031 23.6929 10.3746 24.494L10.7007 24.8109C10.8482 24.594 11.0955 24.45 11.3777 24.446C11.8375 24.4394 12.2155 24.8068 12.222 25.2666C12.2261 25.5487 12.0892 25.8001 11.8766 25.9537L12.9079 26.9559C13.0562 26.8043 13.2622 26.709 13.491 26.7058C13.9508 26.6992 14.3288 27.0666 14.3354 27.5263C14.3386 27.7552 14.2492 27.9638 14.1019 28.1164L15.0749 29.0619C15.2234 28.9089 15.4303 28.8126 15.6604 28.8094C16.1201 28.8028 16.4981 29.1702 16.5047 29.6299C16.508 29.86 16.4176 30.0695 16.269 30.2224L17.4188 31.3398C17.5689 31.1561 17.796 31.0375 18.0518 31.0339C18.5115 31.0273 18.8896 31.3947 18.8961 31.8544C18.8998 32.1102 18.7877 32.3406 18.6084 32.4959L19.1809 33.0523C20.0052 33.8534 21.3229 33.8346 22.124 33.0102L39.3465 15.2887C40.1476 14.4644 40.1288 13.1467 39.3045 12.3456L37.1515 10.2533C36.5925 11.1162 35.6267 11.693 34.5215 11.7088C32.7623 11.7339 31.3157 10.3281 31.2906 8.56887C31.2748 7.46363 31.8238 6.48181 32.6704 5.89835L30.4982 3.78732Z"
    //         fill="#FFB85E"/>
    //   <path
    //     d="M26.2844 13.9787C24.6775 16.9373 20.0979 16.3524 17.7103 13.9788L22.0148 9.73414C22.0148 9.73414 22.6762 10.3955 26.2844 13.9787Z"
    //     fill="black" fillOpacity="0.05"/>
    //   <path
    //     d="M26.0325 13.7126C24.5038 16.527 20.183 15.9789 17.9116 13.7209L17.7102 13.5207L21.7628 9.46803C21.7628 9.46803 22.4243 10.1294 26.0325 13.7126Z"
    //     fill="#E5663B"/>
    //   <path
    //     d="M56.3832 28.3463C56.7811 28.3463 57.1128 28.5011 57.378 28.8106C57.6655 29.1201 57.8092 29.518 57.8092 30.0044C57.8092 30.4908 57.6212 30.8888 57.2454 31.1983C56.8917 31.5078 56.4274 31.6625 55.8526 31.6625H55.2557C54.1723 31.6625 53.1775 31.3862 52.271 30.8335C51.3867 30.2587 50.6903 29.4849 50.1818 28.5121C49.6733 27.5394 49.4191 26.445 49.4191 25.229V17.0379H47.8936C47.4293 17.0379 47.0535 16.9053 46.7661 16.64C46.4787 16.3747 46.335 16.043 46.335 15.6451C46.335 15.2029 46.4787 14.8492 46.7661 14.5839C47.0535 14.3186 47.4293 14.186 47.8936 14.186H49.4191V9.41056C49.4191 8.92418 49.5738 8.52623 49.8833 8.21671C50.1929 7.9072 50.5908 7.75244 51.0772 7.75244C51.5636 7.75244 51.9615 7.9072 52.271 8.21671C52.5806 8.52623 52.7353 8.92418 52.7353 9.41056V14.186H55.5541C56.0184 14.186 56.3942 14.3186 56.6816 14.5839C56.969 14.8492 57.1128 15.2029 57.1128 15.6451C57.1128 16.043 56.969 16.3747 56.6816 16.64C56.3942 16.9053 56.0184 17.0379 55.5541 17.0379H52.7353V25.229C52.7353 26.1134 52.9785 26.854 53.4649 27.4509C53.9513 28.0478 54.5482 28.3463 55.2557 28.3463H56.3832Z"
    //     fill="#575757"/>
    //   <path
    //     d="M78.2494 22.3107C78.2494 22.7308 78.1057 23.0845 77.8183 23.3719C77.5309 23.6372 77.1661 23.7699 76.7239 23.7699H64.1222C64.3654 25.3175 65.0618 26.5666 66.2114 27.5172C67.3832 28.4679 68.8092 28.9432 70.4894 28.9432C71.1526 28.9432 71.838 28.8216 72.5455 28.5784C73.275 28.3353 73.872 28.0368 74.3362 27.6831C74.6457 27.4399 75.0105 27.3183 75.4306 27.3183C75.8506 27.3183 76.1823 27.4288 76.4255 27.6499C76.8234 27.9815 77.0224 28.3574 77.0224 28.7774C77.0224 29.1754 76.8455 29.507 76.4918 29.7723C75.7401 30.3692 74.8005 30.8556 73.673 31.2314C72.5676 31.6073 71.5064 31.7952 70.4894 31.7952C68.6765 31.7952 67.0515 31.4083 65.6145 30.6345C64.1775 29.8386 63.0499 28.7443 62.2319 27.3514C61.436 25.9586 61.0381 24.3779 61.0381 22.6092C61.0381 20.8405 61.4139 19.2598 62.1656 17.867C62.9394 16.452 64.0006 15.3577 65.3492 14.5839C66.7199 13.788 68.2675 13.3901 69.992 13.3901C71.6943 13.3901 73.1645 13.7659 74.4026 14.5176C75.6406 15.2693 76.5913 16.3194 77.2545 17.668C77.9178 19.0166 78.2494 20.5642 78.2494 22.3107ZM69.992 16.242C68.378 16.242 67.0626 16.6842 66.0456 17.5685C65.0507 18.4528 64.4207 19.6356 64.1554 21.1169H75.1985C74.9995 19.6356 74.4468 18.4528 73.5403 17.5685C72.6339 16.6842 71.4511 16.242 69.992 16.242Z"
    //     fill="#575757"/>
    //   <path
    //     d="M98.1987 22.3107C98.1987 22.7308 98.055 23.0845 97.7676 23.3719C97.4801 23.6372 97.1154 23.7699 96.6732 23.7699H84.0715C84.3147 25.3175 85.0111 26.5666 86.1607 27.5172C87.3324 28.4679 88.7584 28.9432 90.4387 28.9432C91.1019 28.9432 91.7873 28.8216 92.4947 28.5784C93.2243 28.3353 93.8212 28.0368 94.2855 27.6831C94.595 27.4399 94.9598 27.3183 95.3799 27.3183C95.7999 27.3183 96.1315 27.4288 96.3747 27.6499C96.7727 27.9815 96.9717 28.3574 96.9717 28.7774C96.9717 29.1754 96.7948 29.507 96.4411 29.7723C95.6894 30.3692 94.7498 30.8556 93.6223 31.2314C92.5168 31.6073 91.4556 31.7952 90.4387 31.7952C88.6258 31.7952 87.0008 31.4083 85.5638 30.6345C84.1267 29.8386 82.9992 28.7443 82.1812 27.3514C81.3853 25.9586 80.9874 24.3779 80.9874 22.6092C80.9874 20.8405 81.3632 19.2598 82.1149 17.867C82.8887 16.452 83.9499 15.3577 85.2985 14.5839C86.6692 13.788 88.2168 13.3901 89.9412 13.3901C91.6436 13.3901 93.1138 13.7659 94.3518 14.5176C95.5899 15.2693 96.5406 16.3194 97.2038 17.668C97.867 19.0166 98.1987 20.5642 98.1987 22.3107ZM89.9412 16.242C88.3273 16.242 87.0119 16.6842 85.9949 17.5685C85 18.4528 84.3699 19.6356 84.1046 21.1169H95.1477C94.9487 19.6356 94.396 18.4528 93.4896 17.5685C92.5832 16.6842 91.4004 16.242 89.9412 16.242Z"
    //     fill="#575757"/>
    //   <path
    //     d="M116.209 29.2749C116.452 29.5402 116.574 29.8718 116.574 30.2697C116.574 30.7561 116.375 31.1651 115.977 31.4967C115.645 31.762 115.291 31.8947 114.916 31.8947C114.429 31.8947 114.031 31.6957 113.722 31.2978L107.421 23.5046L105.763 24.9637V30.0376C105.763 30.524 105.608 30.9219 105.299 31.2314C105.011 31.5188 104.624 31.6625 104.138 31.6625C103.651 31.6625 103.254 31.5188 102.944 31.2314C102.657 30.9219 102.513 30.524 102.513 30.0376V7.38765C102.513 6.90127 102.657 6.51438 102.944 6.22697C103.254 5.91745 103.651 5.7627 104.138 5.7627C104.624 5.7627 105.011 5.91745 105.299 6.22697C105.608 6.51438 105.763 6.90127 105.763 7.38765V20.8848L113.821 13.6885C114.109 13.4011 114.451 13.2574 114.849 13.2574C115.314 13.2574 115.689 13.4343 115.977 13.788C116.264 14.0754 116.408 14.4181 116.408 14.816C116.408 15.2803 116.231 15.6562 115.877 15.9436L109.775 21.3822L116.209 29.2749Z"
    //     fill="#575757"/>
    //   <path
    //     d="M127.966 13.3901C129.713 13.3901 131.271 13.788 132.642 14.5839C134.035 15.3577 135.118 16.441 135.892 17.8338C136.688 19.2266 137.086 20.8184 137.086 22.6092C137.086 24.3779 136.699 25.9586 135.925 27.3514C135.151 28.7443 134.068 29.8386 132.675 30.6345C131.304 31.4083 129.735 31.7952 127.966 31.7952C126.197 31.7952 124.617 31.4083 123.224 30.6345C121.831 29.8386 120.748 28.7443 119.974 27.3514C119.2 25.9586 118.813 24.3779 118.813 22.6092C118.813 20.8184 119.2 19.2266 119.974 17.8338C120.748 16.441 121.831 15.3577 123.224 14.5839C124.617 13.788 126.197 13.3901 127.966 13.3901ZM127.966 16.3747C126.839 16.3747 125.822 16.64 124.915 17.1706C124.031 17.7012 123.334 18.4418 122.826 19.3925C122.317 20.3431 122.063 21.4154 122.063 22.6092C122.063 23.8031 122.317 24.8753 122.826 25.826C123.334 26.7545 124.031 27.4841 124.915 28.0147C125.822 28.5453 126.839 28.8106 127.966 28.8106C129.094 28.8106 130.1 28.5453 130.984 28.0147C131.89 27.4841 132.587 26.7545 133.073 25.826C133.582 24.8753 133.836 23.8031 133.836 22.6092C133.836 21.4154 133.582 20.3431 133.073 19.3925C132.587 18.4418 131.89 17.7012 130.984 17.1706C130.1 16.64 129.094 16.3747 127.966 16.3747Z"
    //     fill="#575757"/>
    // </svg>
    <svg width="153" height="35" viewBox="0 0 153 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="19.7535" height="33.2559" rx="7.50133" transform="matrix(-0.707107 0.707107 0.707107 0.707107 11.026 -2.94165)" fill="#005BBA"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M31.193 0.892589C30.2579 -0.0161405 28.7633 0.00520305 27.8545 0.940263L8.31856 21.0423C7.40983 21.9774 7.43117 23.472 8.36623 24.3808L8.73641 24.7405C8.90369 24.4944 9.18435 24.331 9.5045 24.3265C10.026 24.319 10.4548 24.7358 10.4622 25.2573C10.4668 25.5774 10.3115 25.8626 10.0703 26.0369L11.2401 27.1737C11.4084 27.0018 11.642 26.8937 11.9016 26.89C12.4231 26.8825 12.8519 27.2992 12.8593 27.8207C12.8631 28.0803 12.7617 28.3169 12.5946 28.49L13.6979 29.5623C13.8664 29.3888 14.101 29.2797 14.3619 29.2759C14.8834 29.2685 15.3122 29.6852 15.3197 30.2067C15.3234 30.4676 15.221 30.7053 15.0524 30.8786L16.3565 32.146C16.5267 31.9379 16.7843 31.8035 17.0742 31.7994C17.5957 31.7919 18.0245 32.2087 18.032 32.7302C18.0361 33.0201 17.9091 33.2814 17.706 33.4575L18.3554 34.0886C19.2904 34.9974 20.7851 34.976 21.6939 34.041L41.2298 13.9389C42.1386 13.0039 42.1172 11.5092 41.1822 10.6004L38.7404 8.2274C38.1062 9.2061 37.0108 9.86029 35.7572 9.87819C33.7616 9.90669 32.1208 8.31207 32.0923 6.31652C32.0744 5.0629 32.697 3.94928 33.6572 3.28743L31.193 0.892589Z" fill="#FDD500"/>
        <path d="M26.412 12.4535V12.4535C24.5893 15.8095 19.3945 15.1461 16.6861 12.4536V12.4536L21.5689 7.63875C21.5689 7.63875 22.3191 8.38897 26.412 12.4535Z" fill="black" fill-opacity="0.05"/>
        <path d="M26.1267 12.1516V12.1516C24.3927 15.3441 19.4915 14.7223 16.915 12.161L16.6865 11.9338L21.2835 7.33681C21.2835 7.33681 22.0338 8.08703 26.1267 12.1516Z" fill="#005BBA"/>
        <path d="M60.5531 28.7508C61.0045 28.7508 61.3807 28.9263 61.6816 29.2774C62.0077 29.6285 62.1707 30.0799 62.1707 30.6316C62.1707 31.1833 61.9575 31.6347 61.5312 31.9858C61.1299 32.3369 60.6033 32.5125 59.9513 32.5125H59.2741C58.0453 32.5125 56.9168 32.199 55.8886 31.5721C54.8855 30.92 54.0955 30.0423 53.5187 28.9389C52.9419 27.8354 52.6535 26.5941 52.6535 25.2148V15.9233H50.9232C50.3965 15.9233 49.9702 15.7729 49.6442 15.4719C49.3182 15.171 49.1552 14.7948 49.1552 14.3434C49.1552 13.8419 49.3182 13.4406 49.6442 13.1397C49.9702 12.8387 50.3965 12.6883 50.9232 12.6883H52.6535V7.27142C52.6535 6.71971 52.8291 6.2683 53.1802 5.91721C53.5313 5.56612 53.9827 5.39057 54.5344 5.39057C55.0861 5.39057 55.5375 5.56612 55.8886 5.91721C56.2397 6.2683 56.4152 6.71971 56.4152 7.27142V12.6883H59.6127C60.1393 12.6883 60.5657 12.8387 60.8917 13.1397C61.2177 13.4406 61.3807 13.8419 61.3807 14.3434C61.3807 14.7948 61.2177 15.171 60.8917 15.4719C60.5657 15.7729 60.1393 15.9233 59.6127 15.9233H56.4152V25.2148C56.4152 26.2179 56.6911 27.058 57.2428 27.7351C57.7945 28.4122 58.4716 28.7508 59.2741 28.7508H60.5531Z" fill="#575757"/>
        <path d="M85.3566 21.9045C85.3566 22.3809 85.1936 22.7822 84.8676 23.1082C84.5416 23.4091 84.1278 23.5596 83.6262 23.5596H69.3317C69.6076 25.3151 70.3975 26.732 71.7016 27.8103C73.0307 28.8887 74.6483 29.4279 76.5542 29.4279C77.3065 29.4279 78.084 29.2899 78.8865 29.0141C79.714 28.7382 80.3911 28.3997 80.9178 27.9984C81.2689 27.7226 81.6827 27.5846 82.1591 27.5846C82.6356 27.5846 83.0118 27.71 83.2877 27.9608C83.7391 28.337 83.9648 28.7633 83.9648 29.2398C83.9648 29.6912 83.7641 30.0674 83.3629 30.3683C82.5102 31.0454 81.4444 31.5971 80.1654 32.0235C78.9115 32.4498 77.7078 32.6629 76.5542 32.6629C74.4978 32.6629 72.6546 32.2241 71.0245 31.3463C69.3944 30.4435 68.1154 29.2022 67.1875 27.6223C66.2847 26.0423 65.8333 24.2493 65.8333 22.243C65.8333 20.2368 66.2597 18.4437 67.1123 16.8638C67.99 15.2588 69.1938 14.0174 70.7236 13.1397C72.2784 12.2369 74.0339 11.7855 75.9899 11.7855C77.921 11.7855 79.5886 12.2118 80.993 13.0645C82.3974 13.9171 83.4757 15.1083 84.2281 16.6381C84.9804 18.1678 85.3566 19.9233 85.3566 21.9045ZM75.9899 15.0205C74.1592 15.0205 72.6671 15.5221 71.5135 16.5252C70.385 17.5283 69.6703 18.87 69.3693 20.5502H81.8958C81.6701 18.87 81.0432 17.5283 80.015 16.5252C78.9868 15.5221 77.6451 15.0205 75.9899 15.0205Z" fill="#575757"/>
        <path d="M107.986 21.9045C107.986 22.3809 107.823 22.7822 107.497 23.1082C107.171 23.4091 106.757 23.5596 106.255 23.5596H91.9608C92.2366 25.3151 93.0266 26.732 94.3306 27.8103C95.6598 28.8887 97.2773 29.4279 99.1832 29.4279C99.9356 29.4279 100.713 29.2899 101.515 29.0141C102.343 28.7382 103.02 28.3997 103.547 27.9984C103.898 27.7226 104.312 27.5846 104.788 27.5846C105.265 27.5846 105.641 27.71 105.917 27.9608C106.368 28.337 106.594 28.7633 106.594 29.2398C106.594 29.6912 106.393 30.0674 105.992 30.3683C105.139 31.0454 104.073 31.5971 102.794 32.0235C101.541 32.4498 100.337 32.6629 99.1832 32.6629C97.1268 32.6629 95.2836 32.2241 93.6535 31.3463C92.0234 30.4435 90.7445 29.2022 89.8166 27.6223C88.9138 26.0423 88.4624 24.2493 88.4624 22.243C88.4624 20.2368 88.8887 18.4437 89.7413 16.8638C90.6191 15.2588 91.8228 14.0174 93.3526 13.1397C94.9074 12.2369 96.6629 11.7855 98.619 11.7855C100.55 11.7855 102.218 12.2118 103.622 13.0645C105.026 13.9171 106.105 15.1083 106.857 16.6381C107.609 18.1678 107.986 19.9233 107.986 21.9045ZM98.619 15.0205C96.7883 15.0205 95.2961 15.5221 94.1425 16.5252C93.014 17.5283 92.2993 18.87 91.9984 20.5502H104.525C104.299 18.87 103.672 17.5283 102.644 16.5252C101.616 15.5221 100.274 15.0205 98.619 15.0205Z" fill="#575757"/>
        <path d="M128.415 29.804C128.691 30.105 128.829 30.4812 128.829 30.9326C128.829 31.4843 128.603 31.9482 128.152 32.3244C127.776 32.6253 127.374 32.7758 126.948 32.7758C126.396 32.7758 125.945 32.5501 125.594 32.0987L118.447 23.2587L116.566 24.9138V30.6692C116.566 31.221 116.39 31.6724 116.039 32.0235C115.713 32.3495 115.274 32.5125 114.723 32.5125C114.171 32.5125 113.719 32.3495 113.368 32.0235C113.042 31.6724 112.879 31.221 112.879 30.6692V4.97678C112.879 4.42506 113.042 3.9862 113.368 3.66018C113.719 3.30909 114.171 3.13354 114.723 3.13354C115.274 3.13354 115.713 3.30909 116.039 3.66018C116.39 3.9862 116.566 4.42506 116.566 4.97678V20.2869L125.707 12.124C126.033 11.798 126.422 11.635 126.873 11.635C127.4 11.635 127.826 11.8356 128.152 12.2369C128.478 12.5629 128.641 12.9516 128.641 13.403C128.641 13.9296 128.44 14.356 128.039 14.682L121.118 20.8512L128.415 29.804Z" fill="#575757"/>
        <path d="M141.752 11.7855C143.733 11.7855 145.501 12.2369 147.056 13.1397C148.636 14.0174 149.864 15.2462 150.742 16.8262C151.645 18.4061 152.096 20.2117 152.096 22.243C152.096 24.2493 151.657 26.0423 150.78 27.6223C149.902 29.2022 148.673 30.4435 147.093 31.3463C145.538 32.2241 143.758 32.6629 141.752 32.6629C139.745 32.6629 137.952 32.2241 136.372 31.3463C134.792 30.4435 133.564 29.2022 132.686 27.6223C131.808 26.0423 131.369 24.2493 131.369 22.243C131.369 20.2117 131.808 18.4061 132.686 16.8262C133.564 15.2462 134.792 14.0174 136.372 13.1397C137.952 12.2369 139.745 11.7855 141.752 11.7855ZM141.752 15.171C140.473 15.171 139.319 15.4719 138.291 16.0738C137.288 16.6757 136.498 17.5158 135.921 18.5942C135.344 19.6725 135.056 20.8888 135.056 22.243C135.056 23.5972 135.344 24.8135 135.921 25.8919C136.498 26.9452 137.288 27.7727 138.291 28.3746C139.319 28.9765 140.473 29.2774 141.752 29.2774C143.031 29.2774 144.172 28.9765 145.175 28.3746C146.203 27.7727 146.993 26.9452 147.545 25.8919C148.121 24.8135 148.41 23.5972 148.41 22.243C148.41 20.8888 148.121 19.6725 147.545 18.5942C146.993 17.5158 146.203 16.6757 145.175 16.0738C144.172 15.4719 143.031 15.171 141.752 15.171Z" fill="#575757"/>
    </svg>

  )
  
}
