import ActionTypes from './actionTypes'
import {Actions} from './actions'
import {DateTime} from 'luxon';
import update from 'immutability-helper';
import {PosterSortingEnum} from 'apollo/__generated_types__/globalTypes';

export const INITIAL_PAGE = 0

export type DateRange = {
  start: null | DateTime,
  end: null | DateTime,
}

export type PriceRange = {
  from: null | number,
  to: null | number,
}

export type ObjectsOnPage = 9 | 15 | 27
export type ViewType = 'GRID' | 'LIST'

export interface InitialState {
  filters: {
    date: {
      range: DateRange,
      today: boolean,
      tomorrow: boolean,
      thisWeek: boolean,
    },
    price: {
      range: PriceRange,
      free: boolean
    },
    search: string,
  },
  sort: PosterSortingEnum,
  objectsOnPage: ObjectsOnPage,
  page: number,
  view: ViewType
}

export type DateFilterField = 'range' | 'today' | 'tomorrow' | 'thisWeek'

export const initialState: InitialState = {
  filters: {
    date: {
      range: {
        start: null,
        end: null,
      },
      today: false,
      tomorrow: false,
      thisWeek: false,
    },
    price: {
      range: {
        from: null,
        to: null,
      },
      free: false
    },
    search: '',
  },
  sort: PosterSortingEnum.NEW,
  objectsOnPage: 9,
  page: INITIAL_PAGE,
  view: 'GRID'
}

export default (state = initialState, action: Actions): InitialState => {
  switch (action.type) {
    case ActionTypes.RESET:
      return initialState
    case ActionTypes.RESET_DATE:
      return {
        ...state,
        filters: {
          ...state.filters,
          date: {
            ...state.filters.date, range: {
              start: null,
              end: null,
            }
          }

        }

      }
    case ActionTypes.EDIT_SEARCH:
      return update(state, {
        filters: {
          search: {
            $set: action.payload
          }
        }
      })
    case ActionTypes.EDIT_DATE_FILTER:
      return update(state, {
        filters: {
          date: {
            [action.payload.field]: {
              $set: action.payload.value
            }
          }
        }
      })
    case ActionTypes.EDIT_PRICE_FILTER:
      return update(state, {
        filters: {
          price: {
            [action.payload.field]: {
              $set: action.payload.value
            }
          }
        }
      })
    case ActionTypes.CHANGE_SORT:
      return {...state, sort: action.payload}
    case ActionTypes.CHANGE_OBJECTS_ON_PAGE:
      return {...state, objectsOnPage: action.payload}
    case ActionTypes.OPEN_PAGE:
      return {...state, page: action.payload}
    case ActionTypes.SWITCH_VIEW:
      return {...state, view: action.payload}
    default:
      return state
  }
}