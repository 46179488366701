import React from 'react';
import {DotStaticPage, Triangle} from "../../containers/Pages/Event/Icons/Icons";
import s from './index.module.scss'
import classNames from "classnames/bind";
import CustomButton from "../Button/CustomButton";
import {Trans} from "react-i18next";
import {renderLogo} from "../../helpers/renderLogo";

const cx = classNames.bind(s)

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }
  
  static getDerivedStateFromError(error) {
    console.error(error?.message)
    return {hasError: true};
  }
  
  render() {
    if (this.state.hasError) {
      return <div>
        <section className={cx('Error')}>
          <div className="container">
            <div className={cx('wrap-box')}>
              <Triangle left={-52} top={110} width={51} height={41} fill={'var(--accent-color)'} rotate={'10'}/>
              <Triangle right={102} bottom={110} width={51} height={41} fill={'var(--accent-color)'} rotate={'135'}/>
              <DotStaticPage left={0} width={39} height={39} bottom={145} stroke={'var(--accent-color)'}/>
              <div className={cx('box')}>
                <DotStaticPage left={-184} width={19} height={19} top={10} stroke={'var(--accent-color)'}/>
                <DotStaticPage right={-48} top={140} width={34} height={34} stroke={'var(--accent-color)'}/>
              </div>
              <div className={cx('ErrorContent')}>
                {renderLogo()}
                <p>Loading failed! Please reload.</p>
                <CustomButton type={'button'} background={'#fff'} width={205} color={'var(--accent-color)'}
                              onClick={() => {
                                window.location.reload()
                              }}
                              border={'var(--accent-color)'}>
                  <Trans i18nKey={'Reload'}/>
                </CustomButton>
              </div>
            </div>
          </div>
        </section>
      </div>
    }
    
    return this.props.children;
  }
}

export default ErrorBoundary
