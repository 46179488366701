import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import s from './NotFound.module.scss'
import classNames from 'classnames/bind'
import {Link} from 'react-router-dom'
import {DotStaticPage} from 'containers/Pages/Event/Icons/Icons'
import HeaderCustom from '../../../components/HeaderCustom/HeaderCustom'
import {Triangle} from '../Event/Icons/Icons'
import {Trans, withTranslation} from "react-i18next";
import {withRouter} from "react-router";

const cx = classNames.bind(s)

class NotFound extends PureComponent {
  state = {
    showModalSign: false,
  }

  openModal = () => this.setState({showModalSign: 'login'})
  handleBack = (path = '') => () => {
    if (path) {
      this.props.history.push(path)
      return;
    }
    if (!this.props.location.pathname.includes('/dashboard')) {
      this.props.history.push('/')
    } else {
      if (window.history.length) {
        window.history.back()
      } else {
        window.location.reload()
      }
    }
  }

  render() {
    const {
      className, children, isEmpty = false, content = true, render, location: {pathname}
    } = this.props

    return (
      <div className={`${cx('wrap-static')} ${className}`}>
        <HeaderCustom
          classNames={'header-not-found'}
          links={
            <>
              <Link to='/dashboard'><Trans i18nKey='user.myEvents'/></Link>
              <Link to='/profile' className='profile-link'><Trans i18nKey='user.profile'/></Link>
              <Link to='/create-event' className='outline'><Trans i18nKey="event.createEvent"/></Link>

            </>
          }/>

        <section className={cx('s-box')}>
          <div className="container">
            <div className={cx('wrap-box')}>
              <Triangle left={-52} top={110} width={51} height={41} fill={'var(--accent-color)'} rotate={'10'}/>
              <Triangle right={102} bottom={110} width={51} height={41} fill={'var(--accent-color)'} rotate={'135'}/>
              <DotStaticPage left={0} width={39} height={39} bottom={145} stroke={'var(--accent-color)'}/>
              <div className={cx('box')}>
                <DotStaticPage left={-184} width={19} height={19} top={10} stroke={'var(--accent-color)'}/>
                <DotStaticPage right={-48} top={140} width={34} height={34} stroke={'var(--accent-color)'}/>
                {children}
                {render && render(this.handleBack)}
                <div className={cx('content', {empty: isEmpty})}>
                  {content && <>
                    <button onClick={this.handleBack()}>
                      <Trans
                        i18nKey={`${!pathname.includes('/dashboard') ? 'toHome' : window.history.length ? 'back' : 'user.refresh'}`}/>
                    </button>
                    <div>
                      <strong><Trans i18nKey={'error.ups'}/> </strong>
                      <span><Trans i18nKey={'error.somethingWrong'}/></span>
                    </div>
                  </>}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default withRouter(connect((s) => ({token: s.Profile.token}))(withTranslation()(NotFound)))
