import {Trans} from "react-i18next";
import React from "react";

const TabsControl = ({variables, setVariables}) => {
  const isTeeko = process.env.REACT_APP_TYPE === 'TEEKO'
  const classActive = isTeeko ? 'active' : 'active text-black'
  return <div className={`tabs-control ${!isTeeko ? 'text-black' : ''}`}>
    <button className={variables === 'ALL' ? classActive : ''} onClick={() => setVariables('ALL')}><Trans
      i18nKey={'event.eventCart.tabs.all'}/></button>
    <button className={variables === 'UPCOMING' ? classActive : ''}
            onClick={() => setVariables('UPCOMING')}><Trans i18nKey={'event.eventCart.tabs.active'}/></button>
    <button className={variables === 'PAST' ? classActive : ''} onClick={() => setVariables('PAST')}><Trans
      i18nKey={'event.eventCart.tabs.passed'}/></button>
    <button className={variables === 'DRAFT' ? classActive : ''} onClick={() => setVariables('DRAFT')}><Trans
      i18nKey={'event.eventCart.tabs.draft'}/></button>
  </div>
}
export default TabsControl
