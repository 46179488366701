import 'assets/css/style.scss'
import React from 'react'
import App from 'containers/App'
import {ConnectedRouter} from 'connected-react-router'
import store, {history} from 'store/'
import {ApolloProvider} from '@apollo/client'
import client from 'apollo/'
import {Provider} from 'react-redux'

require('assets/css/' + (process.env.REACT_APP_TYPE || 'TEEKO') + '/root.scss');

export default function Root() {
  
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App history={history}/>
        </ConnectedRouter>
      </Provider>
    </ApolloProvider>
  )
}
