import React, {PureComponent} from 'react';
import {isIE} from 'react-device-detect';
import './../../scss/other.scss';

class CheckIe extends PureComponent {
  render() {

    if(isIE){
      return <div className={'notSupported'}> This browser is deprecated and not supported. Download another browser
        <a href="https://www.google.com/intl/uk_ua/chrome/">Chrome</a>/
        <a href="https://www.opera.com/ru/download">Opera</a>/
        <a href="https://www.mozilla.org/uk/firefox/new/">Firefox</a>.
      </div>
    }
    return <div>

    </div>
  }
}


export default CheckIe;