import React, {memo, useState} from 'react'
import {LANGUAGES} from 'constants/'
import {setLanguage} from 'helpers/localStorage';
import s from './index.module.scss'
import classNames from 'classnames/bind'
import IconAngle from 'components/Icons/Angle';
import {useTranslation} from 'react-i18next';

const cx = classNames.bind(s)

function LanguageSwitcher({className = '', isShort = true, onChange}) {
  const [isOpen, setOpen] = useState(false);
  const {t, i18n} = useTranslation();
  const toggleList = (e) => {
    e.preventDefault()
    setOpen(!isOpen)
  }

  /**
   * @param e - default button event
   * @param lng - any language. will pass to `setLanguage`
   *
   * Hide dropdown after change language
   */
  const changeLanguage = (e, lng) => {
    e.preventDefault();
    setLanguage(lng)
    setOpen(false)
    if (onChange) {
      onChange(lng)
    }
  }
  const locale = i18n.languages && i18n.languages[0]
  return (
    <div className={cx('Container', isOpen && 'Open', className)}>
      <button onClick={toggleList}>
        <IconAngle color='#515151'/>
        {t(`languages.${isShort ? 'short' : 'long'}.${locale}`)}
      </button>
      <ul className={cx('List')}>
        {LANGUAGES.map((lng) => {
          if (lng === locale) return null
          return (
            <li key={lng}>
              <button onClick={e => changeLanguage(e, lng)}>
                {t(`languages.${isShort ? 'short' : 'long'}.${lng}`)}
              </button>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default memo(LanguageSwitcher)
