import error from './error'
import landing from './landing'
import sign from './sign'
import event from './event'
import user from './user'
import footer from './footer'
import languages from './languages'
import searchPage from './search_page'
import postersPage from './posters_page'

export default {
  sign,
  error,
  event,
  landing,
  user,
  languages,
  searchPage,
  postersPage,
  footer,
  helpArmy: 'Help Ukrainian Army',
  showBy: 'Parādīt līdz',
  show: 'šovs',
  copy: 'Kopēt',
  copied: 'Nokopēts',
  aliasDescr1:  'Jūs varat norādīt unikālu aizstājvārdu un sniegt saites cilvēkiem, facebook utt. kā:',
  aliasDescr2: 'Tas ir ērti, jo cilvēki redzēs tikai jūsu pasākumus.',
  learnMore: 'Uzziniet vairāk',
  subscribeForm: {
    header:     'Saņemiet e-pasta ziņojumus no teeko, lai atjauninātu atjauninājumus!',
    success:     'Paldies, ka abonējāt 😁'
  },
  ticketActivation: {
    title:     'Biļešu aktivizēšana',
    ticketCreated:     'Izdota biļete',
    order:     'Orderis'
  },
  toHome:   'Sākums',
  cancel:   'Atcelt',
  notifications: {
    smsSent:     'SMS nosūtīts veiksmīgi.',
    saved:     'Saglabāts',
    error:     'Radusies kļūda!'
  },
  verify: {
    sendEmailAgain:     'Sūtīt e-pastu vēlreiz',
    verificatePhone:     'Apstiprināt tālruņa numuru',
    emailVerificated:     'Apsveicam, e-pasts apstiprināts!',
    emailSended:     'E-pasts nosūtīts',
    email:     'Apstipriniet savu e-pastu.',
    phone:     'Apstipriniet savu tālruņa numuru'
  },
  changeLang:   'Mainīt valodu',
  terms: {
    title:     'Pakalpojumu noteikumi',
    tabs: {
      organizer:       'Organizatoriem',
      customer:       'Pircējiem'
    }
  },
  policy: {
    title: 'Privātuma politika'
  },
  cookie: {
    text:     'Izmantojot teeko, jūs piekrītat mūsu sīkfailu politikai. <0> Uzziniet vairāk. </ 0> ',
    accept:     'Pieņemt',
  },
  send:   'Iesniegt',
  remove:   'Dzēst',
  download:   'Lejupielādēt',
  weekDays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
}
