import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Trans, withTranslation} from 'react-i18next';
import {EyeIcon} from "../../Pages/Event/Icons/Icons";
import {isIncorrectSymbol, replaceSymbol} from "../../../helpers/replaceSymbol";

class PasswordField extends Component {
  state = {
    showPassword: false,
    error: false
  }

  static propTypes = {
    password: PropTypes.object.isRequired,
    editField: PropTypes.func.isRequired,

  }

  render() {
    const {password, editField, t, classNames = ''} = this.props
    if (password.value === "") {
      password.value = ""
    }
    return (
      <div>
        {this.state.error && <p className={'text-danger text-error'}><Trans i18nKey={'event.incorrectSymbol'}/></p>}
        <div className={`password-field ${this.state.showPassword && 'is-show'} ${classNames}`}>
          <input
            required
            className={password.error && 'border-danger'}
            value={password.value}
            title={t('error.Password must have at least 8 characters')}
            minLength={8}
            onChange={(e) => {
              let value = e.target.value;
              if (isIncorrectSymbol(value)) {
                !this.state.error && this.setState({error: true})
              } else {
                this.state.error && this.setState({error: false})
              }
              value = replaceSymbol(value)
              value.length < 30 && editField(value)
            }}
            type={this.state.showPassword ? 'text' : 'password'}/>
          <strong
            role="button"
            onClick={(e) => {
              e.preventDefault()
              this.setState({showPassword: !this.state.showPassword})
            }}>
            <EyeIcon/>
          </strong>
        </div>
      </div>
    )
  }
}

export default withTranslation()(PasswordField)
