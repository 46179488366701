import "babel-polyfill"
import 'isomorphic-unfetch'
import 'react-app-polyfill/ie11';
import React from 'react'
import ReactDOM from 'react-dom'
import Raven from 'raven-js'
import AsyncRoot from 'containers/Root';
import * as serviceWorker from './service-worker'

if (!window.location.href.includes('localhost')) {
  Raven.config('https://8eac533c97a741769922ec08da4d171b@sentry.teeko.io/65', {
    environment: process.env.NODE_ENV
  }).install()
}
// export const INTERCOM_APP_ID = 's727pq88';
// export const INTERCOM_API_BASE = 'https://api-iam.intercom.io';
//
// window.Intercom("boot", {
//   api_base: INTERCOM_API_BASE,
//   app_id: INTERCOM_APP_ID
// });

ReactDOM.render(<AsyncRoot/>, document.getElementById('root'))

serviceWorker.unregister()
// serviceWorker.register({
// //   onSuccess: () => store.dispatch({type: SW_INIT}),
//   onUpdate: (registration) => {
//     // store.dispatch({type: SW_UPDATE, payload: registration})
//
//     const waitingServiceWorker = registration.waiting
//     if (waitingServiceWorker) {
//       waitingServiceWorker.addEventListener('statechange', (event) => {
//         if (event.target.state === 'activated') {
//           store.dispatch({type: SW_UPDATE, payload: registration})
//         }
//       })
//       waitingServiceWorker.postMessage({type: 'SKIP_WAITING'})
//     }
//   }
//
// })
;
