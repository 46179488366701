import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { edit } from 'store/Base/actions';
import { Trans, withTranslation } from 'react-i18next';
import { compose } from 'redux';

class PhoneNotVerify extends PureComponent {
  render() {
    return <button className='btn-link' onClick={this.props.showModalPhone}><Trans i18nKey='verify.phone' /></button>
  }
}

const mapDispatch = (dispatch) => ({
  showModalPhone() {
    dispatch(edit({
      field: 'showModalPhoneVerification',
      value: true
    }))
  }
})

export default compose(
  connect(null, mapDispatch),
  withTranslation()
)(PhoneNotVerify)
