export default {
  'Enter': 'Enter',
  profile: 'Profile',
  account: 'My account',

  general: 'General',
  finances: 'Finance',
  infoFull: 'Your account is full',
  infoNotFull: 'Your account is incomplete',
  editData: 'Edit data',
  cashWithdrawal: 'Cash withdrawal',
  withdrawMoney: 'Withdraw money',
  uploadImageDescription: 'Download scanned copies of documents (passport, identification number, extracts from the unified register, certificate from the bank about opening an account)',
  uploadImageDescriptionNatural: 'Download scanned copies of documents (passport, identification number)',
  uploadImageDetail1: 'passport scans from pages 1 to 11, including the registration page and the next blank (so that financial processing can see that the registration in the scans is the latest)',
  uploadImageDetail2: 'scans must be spread (2 pages per scan)',
  uploadImageDetail3: 'scan of the internal ID card, which, instead of a passport, must be on both sides',
  uploadImageFormat: 'Files must be in the formats: jpeg, jpg, pdf',
  cashDescription: 'You can withdraw money to existing accounts, or create a new one by clicking the “add new account” button',
  addNewWayPay: 'Add New Payment Method',
  personInfo: 'Personal information',
  editDescription: 'Fields marked with an asterisk are required.',
  dragInDrop: 'Drag file here or',
  dragInDropImage: 'Drag the image here or',

  uploadFileWithPC: 'download from computer',
  reset: 'Reset',
  refresh: 'Refresh',
  scanType: {
    PASSPORT: 'The passport',
    PASSPORT_PE: 'A copy of an individual entrepreneur\'s passport (all spreads where there are records and a spread of registration, even if it is not there)',
    PASSPORT_OOO: 'Passports of the director of a legal entity (p. 1-6, registration and the next page after it)',
    PASSPORT_OO: 'Passports of the director of a legal entity (p. 1-6, registration and the next page after it)',

    INN: 'Identification number',
    INN_PE: 'Copy of TIN of FLP',
    INN_OOO: 'TIN of the director of the legal entity faces',
    INN_OO: 'TIN of the director of the legal entity faces',

    REGISTRY_REPORT_PE: 'Extract from the Unified State Register / Certificate of state registration of individual entrepreneur',
    REGISTRY_REPORT_OOO: 'Extracts from the USR / certificate of state. registration of legal entity',
    REGISTRY_REPORT_OO: 'Extracts from the USR / certificate of state. registration of legal entity',

    BANK_REPORT_PE: 'Bank certificate on opening a current account',
    BANK_REPORT_OOO: 'Certificate of opening a current account from the Bank (new sample with indication of IBAN)',
    BANK_REPORT_OO: 'Certificate of opening a current account from the Bank (new sample with indication of IBAN)',

    COMPANY_STATUTE_OOO: 'Of the charter of legal entities faces',
    COMPANY_STATUTE_OO: 'Of the charter of legal entities faces',
    ORG_STRUCTURE_OOO: 'Ownership structure',
    ORG_STRUCTURE_OO: 'Ownership structure',

    DIRECTOR_PROTOCOL_OOO: 'Protocol, as well as the order on the appointment of the director',
    DIRECTOR_PROTOCOL_OO: 'Protocol, as well as the order on the appointment of the director',
    FINANCE_REPORT_OO: 'Financial report for the year',
    FONDY_CONTRACT: '',
  },
  verificationType: {
    PENDING: 'undergoing verification',
    CANCELED: 'not confirmed',
    VERIFIED: 'confirmed'
  },
  finance: {
    PENDING: 'The bill is under review, please wait',
    CANCELED: 'The bill is not accepted, please contact support',
    VERIFIED: 'Bill Accepted',
    rs: 'c/a',
    paymentOnАccount: 'Payment to the account',
    paymentOnCard: 'Payment to the card',
    companyName: 'Company name',
    mfo: 'MFO',
    edrpou: 'EDRPOU',
    creditCard: 'Credit card',
    cardNumber: 'Enter card number',
    bankAccountNumber: 'Bank Account Number',
    uploadPassport: 'Upload passport‘s scan',
    uploadId: 'Upload scan of id number',
    uploadContract: 'Upload scan of the signed contract with fondy',
    naturalPersons: 'Nat. person',
    naturalPersonsFull: 'Individual',
    privateEntrepreneurFull: 'Entepreneur',
    oooFull: 'Limited liability company',
    ooFull: 'Public organization',
    privateEntrepreneur: 'Private',
    ooo: 'LLC',
    oo: 'PO',
    iban: 'IBAN',
    ltd: 'LTD',
    statement: 'Scan an extract from the single registry',
    sertificateFromBank: 'A certificate from the bank about opening an account',
  },
  addNewFinance: 'Add a new account',
  email: 'Email',
  firstName: 'First name',
  lastName: 'Last name',
  phone: 'Phone',
  birthday: 'Birthday',
  changePassword: 'Change password',
  oldPassword: 'Old password',
  newPassword: 'New password',
  passwordChanged: 'Password successfully changed.',
  myEvents: 'My events'
}
