import React, { Component } from 'react'

class ErrorPage extends Component {
  render() {
    return (
      <div>ОШИБКА</div>
    );
  }
}

export default ErrorPage;