export default {
  intro: {
    header: 'Obsługa biletów na Twoje imprezy',
    analytics:  'Analytics and reports',
    tickets:  'Sprawdź i aktywacja biletów',
    money:  'Wycofanie z karty',
    notifications:  'Powiadomienia klientów przez SMS',
    fb:  'Synchronizuj z nFacebook',
    promocode:  'Kody promocyjne do rabatu'
  },
  howItWorks: {
    title: 'Jak to działa?',
    items: [
      {
        header: 'Twórz i dostosowuj swoje wydarzenia',
        text:  'Menu pozwala tworzyć wydarzenia i zarządzać nimi. Na pierwszy rzut oka widać, czy sprzedaż jest aktywna i ile sprzedano biletów. Możesz także dostosować wygląd swoich wydarzeń, aby pasowały do marki, przesyłając obrazy banerów. ',
        list: ['Szybka rejestracja 24/7', 'Konta z różnymi prawami dostępu', 'Powiadomienie klienta przez SMS']
      },
      {
        header: 'Promuj i reklamuj swoje wydarzenia',
        text:  'Nasz system pozwala w wygodny sposób rozładować bazę danych uczestników wydarzeń do zaimportowania do systemów pocztowych takich jak MailChimp lub eSputnik.',
        list: ['Dodaj link\'kup teraz\'w reklamie online', 'Elastyczny system kodów promocyjnych', 'Użyj integracji MailChimp do promocji e-mailowej']
      },
      {
        header:  'Zarządzaj swoimi klientami i przeglądaj wyniki',
        text:  'Różne opcje raportów pomogą Ci przeanalizować sprzedaż. Również z pomocą naszego serwisu możesz importować i eksportować klientów. ',
        list: ['Różne opcje raportów', 'Importuj i eksportuj klientów', 'Zobacz, jak klienci znajdują Cię dzięki naszym raportom']
      }
    ]
  },
  pricing: {
    title: 'Polityka cenowa',
    items: [
      {
        header: 'Bezpłatne dla organizatorów wydarzeń',
        text:  'Brak kosztów konfiguracji Brak miesięcznej subskrypcji',
      },
      {
        header:  'Darmowe bilety na darmowe imprezy',
        text:  'Za darmo korzystaj z dowolnych funkcji. Jeśli wydarzenie jest bezpłatne, my też 😁 ',
      },
      {
        header:  'Niska prowizja za płatne wydarzenia',
        text:  'Łącznie usuwa się 5% - uruchomienie systemu przetwarzania płatności i zlecenie usługi biletowej',
      },
    ]
  },
  aboutUs: {
    title: 'Najlepszy wydawca pomocy',
    text:  'Teeko to platforma do organizacji sprzedaży biletów, w której wzięliśmy pod uwagę najlepsze doświadczenia związane z organizowaniem zarówno małych, jak i dużych imprez (1500+ osób). Ty, jako organizator lub kupujący bilet, jesteś naszą rodziną. Stale rozwijamy usługę i zawsze chętnie słuchamy o Twoich pomysłach i potrzebach. '
  },
  advantages: {
    title:  'Korzyści',
    header:  'Korzyści z pracy z nami',
    items: [
      {
        header:  'Wsparcie dla wszystkich kwestii związanych z usługą',
        text:  'Towarzyszymy organizatorowi od początku sprzedaży biletów do ostatniego odwiedzającego'
      },
      {
        header:  'Szybkie uruchamianie. Sprzedaż biletów',
        text:  'Zazwyczaj uruchomienie jest możliwe w ciągu 1 dnia roboczego'
      },
      {
        header:  'Szybki procent sprzedaży biletów',
        text:  'Organizator nie musi czekać na zakończenie imprezy, aby zdobyć pieniądze'
      }
    ]
  },
  startCooperate: {
    header:  'Zacznij sprzedawać bilety z teeko i zarabiaj więcej!',
    btn:  'zacznij teraz'
  }
}