import { gql } from '@apollo/client';


// fragment EventFields on PosterEventNode {
//   id
//   slug
//   currency
//   begins
//   image
//   timezoneField
//   defaultLanguage
//   isMultilanguage

//   name
//   nameEn
//   nameRu
//   nameUk
//   nameHe
//   namePl
//   nameLv

//   locationName
//   locationNameEn
//   locationNameRu
//   locationNameUk
//   locationNameHe
//   locationNamePl
//   locationNameLv

//   address
//   prices
// }

export default gql`
  query PosterEvents (
    $filter: PosterFilterInput
    $sort: PosterSortingEnum
    $before: String
    $after: String
    $first: Int
    $last: Int,
    $withDescription: Boolean = false,
  ) {
    posterEvents (
      filter: $filter
      sort: $sort
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      totalCount
      edges {
        node @include(if: $withDescription) {
          shortDescription
          id
          slug
          currency
          begins
          image
          timezoneField
          defaultLanguage
          isMultilanguage
          isOnline
          name
          nameEn
          nameRu
          nameUk
          nameHe
          namePl
          nameLv
        
          locationName
          locationNameEn
          locationNameRu
          locationNameUk
          locationNameHe
          locationNamePl
          locationNameLv
        
          address
          prices
        }
        node @skip(if: $withDescription) {
          shortDescription
          id
          slug
          currency
          begins
          isOnline
          image
          timezoneField
          defaultLanguage
          isMultilanguage
        
          name
          nameEn
          nameRu
          nameUk
          nameHe
          namePl
          nameLv
        
          locationName
          locationNameEn
          locationNameRu
          locationNameUk
          locationNameHe
          locationNamePl
          locationNameLv
        
          address
          prices
        }
      }
    }
  }
`