import React from 'react';
import classNames from "classnames/bind";
import s from './index.module.scss'
import * as PropTypes from "prop-types";
import Loader from "../Loader";
import {Link} from "react-router-dom";

const cx = classNames.bind(s);

class CustomButton extends React.Component {
  render() {
    let {
      classNames,
      disabled,
      onClick,
      type,
      border,
      background,
      width,
      height,
      color,
      children,
      onMouseDown,
      options = {},
      loading = false,
      href = false
    } = this.props;
    const styled = {
      background: background,
      borderRadius: '7px',
      width: `${width}px`,
      height: `${height}px`,
      color: color,
      border: `1px solid ${border}`
      
    }
    return <WrapBtn href={this.props.href}>
      <button disabled={disabled || loading} {...options} type={type}
              onMouseDown={() => onMouseDown && onMouseDown()}
              className={cx('custom-btn', classNames ? classNames : '', {loading})}
              style={styled}
              onClick={(e) => {
                if (onClick) {
                  onClick(e)
                }
              }}>
        {loading ? <span className={cx('loaderWrap')}> <Loader/></span> : children}
      </button>
    </WrapBtn>
  }
}

const WrapBtn = ({href, children}) => {
  if (!href) return children
  return <Link to={href}>{children}</Link>
}

CustomButton.propTypes = {
  classNames: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.any,
  type: PropTypes.string,
  border: PropTypes.string,
  background: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  children: PropTypes.any,
  loading: PropTypes.bool
}

CustomButton.defaultProps = {
  classNames: '',
  disabled: false,
  type: '',
  border: 'transparent',
  background: 'var(--accent-color)',
  width: 400,
  height: 40,
  color: '#fff'
}
export default CustomButton
