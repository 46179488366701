import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Trans, withTranslation} from 'react-i18next';
import s from './index.module.scss'

/**
 * @desc Base component for display error text
 *
 * @param { string } error - It will translate, using locale files. If there is no translates - will display it field
 */
class FieldError extends Component {
  ref = React.createRef()

  static propTypes = {
    error: PropTypes.string.isRequired,
    style: PropTypes.object,
    className: PropTypes.string,
    align: PropTypes.oneOf(['center', 'right', 'left']),
    size: PropTypes.oneOf(['small', 'medium', 'big']),
    localPath: PropTypes.string
  }

  componentDidMount() {
    if (this.ref && this.ref.current) this.ref.current.scrollIntoView({block: "center"})
  }


  static defaultProps = {
    align: 'right',
    size: 'small'
  }

  render() {
    const {error, style, className, align, size, classNames = '', localPath = 'error'} = this.props
    return <p ref={this.ref}
              style={style}
              className={`text-danger ${s[align]} ${s[size]} ${className} ${classNames}`}>
      {error && <Trans i18nKey={`${localPath}.${error}`}/>}
    </p>
  }
}

export default withTranslation()(FieldError)
