import React, { useState, memo, useEffect, useRef } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import Loader from 'components/Loader';
import { useTranslation } from 'react-i18next';
import InputWithButton from 'components/InputWithButton';
import { useQuery } from '@apollo/client';
import AUTOCOMPLETE_EVENT_NAME from 'apollo/queries/AUTOCOMPLETE_EVENT_NAME';
import { AutocompleteEventNameVariables, AutocompleteEventName } from 'apollo/__generated_types__/AutocompleteEventName';
import IconSearch from 'components/Icons/Search';

const cx = classNames.bind(styles)

type FocusEvent =  React.FocusEvent<HTMLInputElement>
type Props = {
  value: string,
  onChange?: (value: string) => void,
  onFocus?: (e: FocusEvent) => void,
  onBlur?: (e: FocusEvent) => void,
  withButton?: boolean,
  inputClassName?: string,
  buttonClassName?: string,
}

function SearchInput({ value, onChange, onFocus, onBlur, withButton, inputClassName, buttonClassName }: Props) {
  const [visible, setVisible] = useState(false)
  const ComponentRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()

  useEffect(() => {
    document.onmousedown = visible
      ? (e: any) => {
        if (ComponentRef.current && !ComponentRef.current.contains(e.target)) {
          document.onmousedown = null
          setVisible(false)
        }
      }
      : null

    return () => {
      document.onmousedown = null
    }
  }, [visible])

  function _onChange (e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value
    onChange && onChange(value)
    // If user input any letter - init loading options for keyword
    setVisible(false)

    // in order to lazy load autocomplete options
    setTimeout(() => {
      setVisible(true)
    }, 500)
  }

  function _onFocus (e: FocusEvent) {
    onFocus && onFocus(e)
  }

  function onSelectOption (option: string) {
    onChange && onChange(option)
    setVisible(false)
  }

  return (
    <div className={cx('Component')} ref={ComponentRef}>
      <InputWithButton
        inputClassName={inputClassName}
        placeholder={t('postersPage.search')}
        onChange={_onChange}
        onFocus={_onFocus}
        value={value}
        onBlur={onBlur}
        buttonClassName={buttonClassName}
        buttonType={'submit'}
        buttonIcon={withButton ? <IconSearch /> : undefined}
      />

      {visible && <List keyword={value} onSelect={onSelectOption} />}
    </div>
  )
}

const List = memo(function ({ keyword, onSelect }: { keyword: string, onSelect: (opt: string) => void }) {
  const { loading, data, error } = useQuery<AutocompleteEventName, AutocompleteEventNameVariables>(AUTOCOMPLETE_EVENT_NAME, {
    variables: {
      part: keyword
    }
  })

  return (
    <div className={cx('List')}>
      {loading && <Loader className={cx('Loader')} />}
      {!loading && data && data.autocompleteName && data.autocompleteName.map(name => {
        if (!name) return null
        return <button className={cx('ListItem')} type='button' onClick={() => onSelect(name)} key={name}>{name}</button>
      })}
    </div>
  )
})

export default memo(SearchInput)
