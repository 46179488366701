export default {
  organizerLanding: 'Pricing',
  company: 'Company',
  aboutUs: 'About us',
  team: 'team',
  career: 'Career',
  contacts: 'Contacts',
  resources: 'Organizers',
  terms: 'Terms of Use',
  policy: 'Privacy Policy',
  support: 'Knowledge Base',
  faq: 'FAQ',
  telegram: 'Contact us on telegram',
  
  office: {
    title: 'Contacts',
    street: 'Shaul Harnam 6, 38 ',
    city: 'Petakh Tikva, Israel, 4977396',
    address: 'Petakh Tikva, Shaul Harnam 6, 38'
  },

  madeBy: "made by",
}
